import React, { useEffect, useState } from "react";
import { Link, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { iconArrows } from "@wfp/icons";

import {
  MainNavigation,
  MainNavigationItem,
  SubNavigation,
  SubNavigationHeader,
  SubNavigationTitle,
  SubNavigationContent,
  SubNavigationList,
  SubNavigationGroup,
  SubNavigationItem,
  SubNavigationLink,
  User,
} from "@wfp/ui";

import Button from "../Button";
import Icon from "../Icon";
import { getUserDescription } from "../../utils/authentication";
import { useTranslation } from "react-i18next";
import { I18nConst, languages, onChangeLanguage } from "../../utils/i18n";
import { isExternalUser, isSystemAdministrator } from "../../utils/roles";

import style from "./style.scss";
// import LanguageNavigationItem from '../LanguageNavigationItem';
// import UserProfileNavigationItem from '../UserProfileNavigationItem';

// A wrapper of the MainNavigation component included in de WFP UI Kit
// which allows to create a menu with a defined set of items
const WfpManagerMainNavigation = ({
  loading,
  user,
  onClickChangeCountry,
  country,
  history,
}) => {
  const { t } = useTranslation();
  const [hasAdministratorRole, setAdministratorRole] = useState(false);
  const [hasGuestRole, setGuestRole] = useState(false);
  const selectedLanguage =
    localStorage.getItem(I18nConst.LANGUAGE_LOCAL_STORAGE_KEY) || "en";
  const mainNavigationProps = {
    logo: <Link to="/representative/">{t("header.wfp")}</Link>,
    pageWidth: "full",
  };

  useEffect(() => {
    const isAdministrator = isSystemAdministrator(user);
    setAdministratorRole(isAdministrator);
    if(!isAdministrator) {
      setGuestRole(isExternalUser(user));
    }
  }, [user]);

  const changeLanguage = async (option) => {
    await onChangeLanguage(option);
  };

  const UserNavigation = (
    <SubNavigation>
      <SubNavigationHeader>
        <SubNavigationLink>
          <Button
            kind="primary"
            small
            onClick={() => history.push("/logout")}
          >
            {t("header.logout")}
          </Button>
        </SubNavigationLink>
      </SubNavigationHeader>
    </SubNavigation>
  );
  const LanguageNavigation = (
    <SubNavigation>
      <SubNavigationHeader>
        <SubNavigationTitle>{t("header.chooseLanguage")}</SubNavigationTitle>
      </SubNavigationHeader>
      <SubNavigationContent>
        <SubNavigationList>
          <SubNavigationGroup>
            {languages.map(language => (
              <SubNavigationItem key={`navbar-languages-${language.value}`}>
                <Link
                  href="#"
                  onClick={() => changeLanguage(language)}
                >
                  {language.engLabel ? (
                    <>
                      {language.engLabel}&nbsp;
                      <span className={style.nativeLabel}>
                        ({language.label})
                      </span>
                    </>
                  ) : (
                    language.label
                  )}
                </Link>
              </SubNavigationItem>
            ))}
          </SubNavigationGroup>
        </SubNavigationList>
      </SubNavigationContent>
    </SubNavigation>
  );

  const navItems = [
    {
      to: "/wfpManager/configuration",
      label: "header.configuration",
      hide: hasGuestRole,
    },
    {
      to: "/systemAdmin/platformAccess",
      label: "header.platformAccess",
      hide: !hasAdministratorRole,
    },
    {
      to: "/wfpManager/infohub",
      label: "header.infoHub",
      hide: hasGuestRole,
    },
  ];
  const userDropdowns = [
    {
      component: (
        <User
          alt={user.username}
          name={getUserDescription(user)}
          ellipsis
          missingImage="letter"
        />
      ),
      subNavigation: UserNavigation,
      key: "profileDropdown",
    },
    {
      component: <span>{selectedLanguage.toUpperCase()}</span>,
      subNavigation: LanguageNavigation,
      key: "languageDropdown",
    },
  ];

  return loading ? (
    <MainNavigation {...mainNavigationProps}>
      <MainNavigationItem>
        <Link to="/logout">{t("header.logout")}</Link>
      </MainNavigationItem>
    </MainNavigation>
  ) : (
    <MainNavigation {...mainNavigationProps}>
      {navItems.map(({ to, label, hide }) => !hide && (
        <MainNavigationItem key={`header-navigation-item-${label}`}>
          <Link {...{ to }}>{t(label)}</Link>
        </MainNavigationItem>
      ))}
      {!hasGuestRole && <MainNavigationItem>
        <Button onClick={onClickChangeCountry} className={style.navigationButton}>
          <div className={style.button}>
            {`${country.flag} ${country.name}`}
            <Icon
              icon={iconArrows}
              width="16"
              height="16"
              style={{ marginLeft: "0.5rem", transform: "rotate(90deg)" }}
              fill="#ffffff"
            />
          </div>
        </Button>
      </MainNavigationItem>}
      {userDropdowns.map(({ component, subNavigation, key }) => (
        <MainNavigationItem
          key={`header-navigation-dropdown-${key}`}
          className="wfp--main-navigation__user"
          {...{ subNavigation }}
        >
          {component}
        </MainNavigationItem>
      ))}
    </MainNavigation>
  );
};

// propTypes for the WfpManagerMainNavigation component
WfpManagerMainNavigation.propTypes = {
  country: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  user: PropTypes.any,
  onClickChangeCountry: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
};

// defaultProp for the WfpManagerMainNavigation component
WfpManagerMainNavigation.defaultProp = {
  user: undefined,
};

// withRouter is needed because the component need to use the Link component of react-router-dom
export default withRouter(WfpManagerMainNavigation);

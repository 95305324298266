import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import {
  Button,
  MainNavigation,
  MainNavigationItem,
  SubNavigation,
  SubNavigationHeader,
  SubNavigationTitle,
  SubNavigationContent,
  SubNavigationList,
  SubNavigationGroup,
  SubNavigationItem,
  SubNavigationLink,
  User,
} from "@wfp/ui";

import { updateTraderRepresentative } from "../../actions";
import { getUserDescription } from "../../utils/authentication";
import {
  getUserLanguage,
  languages,
  onChangeLanguage,
} from "../../utils/i18n";

import style from "./style.scss";

// A function to decide if the new market offer button is disabled based on the
// current route
export const newMarketOfferButtonDisabled = history => (
  history.location.pathname.includes("marketOfferDetails")
  || history.location.pathname.includes("marketOfferView")
);

export const newDirectOfferButtonDisabled = history => (
  history.location.pathname === "/representative/market"
);

export const offersButtonDisabled = history => (
  history.location.pathname === "/representative/"
);

// A wrapper of the RepresentativeMainNavigation component included in the WFP
// UI Kit which allows to create a menu with a defined set of items
const RepresentativeMainNavigation = ({
  loading,
  user,
  onClickNewMarketOffer,
  onClickNewDirectOffer,
  onClickOffers,
  history,
  traderRepresentative,
  updateTraderRepresentative,
}) => {
  const { t } = useTranslation();
  const selectedLanguage = getUserLanguage();
  const mainNavigationProps = {
    logo: <Link to="/representative/">{t("header.wfp")}</Link>,
    pageWidth: "full",
  };

  const changeLanguage = async (option) => {
    await onChangeLanguage(option);
  };

  // TODO: Maybe these should be their own components?
  const UserNavigation = (
    <SubNavigation>
      <SubNavigationHeader>
        <SubNavigationTitle>{t("header.welcome")}!</SubNavigationTitle>
        <SubNavigationLink>
          <Button
            kind="secondary"
            small
            onClick={() => history.push("/logout")}
          >
            {t("header.logout")}
          </Button>
        </SubNavigationLink>
      </SubNavigationHeader>
      <SubNavigationContent>
        <Link to={"/representative/profile"}>
          {t("header.profile")}
        </Link>
      </SubNavigationContent>
    </SubNavigation>
  );
  const LanguageNavigation = (
    <SubNavigation>
      <SubNavigationHeader>
        <SubNavigationTitle>{t("header.chooseLanguage")}</SubNavigationTitle>
      </SubNavigationHeader>
      <SubNavigationContent>
        <SubNavigationList>
          <SubNavigationGroup>
            {languages.map(language => (
              <SubNavigationItem key={`navbar-languages-${language.value}`}>
                <Link
                  href="#"
                  onClick={() => changeLanguage(language)}
                >
                  {language.engLabel ? (
                    <>
                      {language.engLabel}&nbsp;
                      <span className={style.nativeLabel}>
                        ({language.label})
                      </span>
                    </>
                  ) : (
                    language.label
                  )}
                </Link>
              </SubNavigationItem>
            ))}
          </SubNavigationGroup>
        </SubNavigationList>
      </SubNavigationContent>
    </SubNavigation>
  );

  const navItems = [
    {
      onClick: onClickOffers,
      label: "header.offerView",
    },
    {
      onClick: onClickNewDirectOffer,
      label: "header.farmersMarket",
    },
    {
      onClick: onClickNewMarketOffer,
      label: "header.newTraderDemand",
      isPrimary: true,
    },
  ];
  const userDropdowns = [
    {
      component: (
        <User
          alt={user.username}
          name={getUserDescription(user)}
          ellipsis
          missingImage="letter"
        />
      ),
      subNavigation: UserNavigation,
      key: "profileDropdown",
    },
    {
      component: <span>{selectedLanguage.toUpperCase()}</span>,
      subNavigation: LanguageNavigation,
      key: "languageDropdown",
    },
  ];

  useEffect(() => {
    if (
      selectedLanguage
      && traderRepresentative.language
      && traderRepresentative.language !== selectedLanguage
    ) {
      updateTraderRepresentative(
        traderRepresentative.id,
        {
          ...traderRepresentative,
          organization: traderRepresentative.organization.id,
          language: selectedLanguage,
        },
      );
    }
  }, [selectedLanguage, traderRepresentative]);

  return loading ? (
    <MainNavigation {...mainNavigationProps}>
      <MainNavigationItem>
        <Link to="/logout">{t("header.logout")}</Link>
      </MainNavigationItem>
    </MainNavigation>
  ) : (
    <MainNavigation {...mainNavigationProps}>
      {navItems.map(({ onClick, label, isPrimary }) => (
        <MainNavigationItem key={`header-navigation-item-${label}`}>
          {isPrimary ? (
            <Button {...{ onClick }} className={style.navigationButton}>{t(label)}</Button>
          ) : (
            <Link to="#" {...{ onClick }}>{t(label)}</Link>
          )}
        </MainNavigationItem>
      ))}
      {userDropdowns.map(({ component, subNavigation, key }) => (
        <MainNavigationItem
          key={`header-navigation-dropdown-${key}`}
          className="wfp--main-navigation__user"
          {...{ subNavigation }}
        >
          {component}
        </MainNavigationItem>
      ))}
    </MainNavigation>
  );
};

// propTypes for the RepresentativeMainNavigation component
RepresentativeMainNavigation.propTypes = {
  loading: PropTypes.bool.isRequired,
  user: PropTypes.any,
  history: PropTypes.object.isRequired,
  onClickNewMarketOffer: PropTypes.func.isRequired,
  onClickNewDirectOffer: PropTypes.func.isRequired,
  onClickOffers: PropTypes.func.isRequired,
  traderRepresentative: PropTypes.object.isRequired,
  updateTraderRepresentative: PropTypes.func.isRequired,
};

// defaultProp for the RepresentativeMainNavigation component
RepresentativeMainNavigation.defaultProps = {
  user: {},
  traderRepresentative: {},
};

const mapStateToProps = (state) => ({
  traderRepresentative: (
    state.traderRepresentativeDetails
    && state.traderRepresentativeDetails.data
  ),
});

const mapDispatchToProps = (dispatch) => ({
  updateTraderRepresentative: (id, data) => dispatch(updateTraderRepresentative(id, data)),
});

// withRouter is needed because the component need to use the Link component of react-router-dom
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(RepresentativeMainNavigation));

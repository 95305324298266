import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import PageStandard from "../../components/PageStandard";
import { getCountryObjectFromCache, valueOrDefault } from "../../utils";
import List from "./components/List";
import PropTypes from "prop-types";
import { administrativeAreasDetailsReset, listAdministrativeAreas, listAdministrativeAreasReset, listAdministrativeAreaTypes, listAdministrativeAreaTypesReset } from "../../actions";
import ButtonsWrapper from "../../components/ButtonsWrapper";
import Button from "../../components/Button";
import AdministrativeAreasEditModal from "./components/Modal";
import { iconAdd } from "@wfp/icons";

export const WfpManagerAdministrativeAreasView = ({
  isFetching,
  errorMessage,
  administrativeAreas,
  administrativeAreaTypes,
  page,
  count,
  listAdministrativeAreas,
  listAdministrativeAreaTypes,
  listAdministrativeAreaTypesReset,
  listAdministrativeAreasReset,
  administrativeAreasDetailsReset,
}) => {
  const { t } = useTranslation();
  const [modalOptions, setModalOptions] = useState({isOpen: false, id: undefined, type: undefined});

  // On component mount the data are fetched
  useEffect(() => {
    listAdministrativeAreas({page_size: 9999, page: 1});
    listAdministrativeAreaTypes();
    return () => {
      listAdministrativeAreasReset();
      listAdministrativeAreaTypesReset();
    };
  }, []);

  const onClickAdd = () => {
    administrativeAreasDetailsReset();
    setModalOptions({isOpen: true, id: "add", type: "create"});
  };

  return (
    <PageStandard
      additional={(
        <ButtonsWrapper>
          <Button icon={iconAdd} onClick={onClickAdd}>
            {t("common.add") + " " + t("configuration.item.administrativeAreas")}
          </Button>
        </ButtonsWrapper>
      )}
      title={t("configuration.item.administrativeAreas")}
    >
      <List
        {...{
          isFetching,
          errorMessage,
          administrativeAreas,
          administrativeAreaTypes,
          page,
          count,
          setModalOptions,
        }}
      />
      <AdministrativeAreasEditModal
        modalOptions={modalOptions}
        setModalOptions={setModalOptions}
        administrativeAreas={administrativeAreas}
        administrativeAreaTypes={administrativeAreaTypes}
      />
    </PageStandard>
  );
};

WfpManagerAdministrativeAreasView.propTypes = {
  isFetching: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string.isRequired,
  administrativeAreas: PropTypes.array.isRequired,
  administrativeAreaTypes: PropTypes.array.isRequired,
  page: PropTypes.number.isRequired,
  count: PropTypes.number.isRequired,
  listAdministrativeAreas: PropTypes.func.isRequired,
  listAdministrativeAreaTypes: PropTypes.func.isRequired,
  listAdministrativeAreaTypesReset: PropTypes.func.isRequired,
  listAdministrativeAreasReset: PropTypes.func.isRequired,
  administrativeAreasDetailsReset: PropTypes.func.isRequired,
};

export const mapStateToProps = (state) => {
  const countryID = getCountryObjectFromCache().id;
  const filteredAdministrativeAreas = state.listAdministrativeAreas.data.results.filter(item => item.country === countryID);
  const filteredAdministrativeAreaTypes = state.listAdministrativeAreaTypes.data.results.filter(item => item.country === countryID);
  return {
    administrativeAreas: filteredAdministrativeAreas,
    administrativeAreaTypes: filteredAdministrativeAreaTypes,
    isFetching: state.listAdministrativeAreas.isFetching,
    errorMessage: state.listAdministrativeAreas.errorMessage,
    page: 1,
    count: valueOrDefault(state.listAdministrativeAreas.data.results.length, 0),
  };
};

// Maps functions to dispatch actions
export const mapDispatchToProps = (dispatch) => {
  return {
    listAdministrativeAreas: () => dispatch(listAdministrativeAreas({page_size: 9999, page: 1})),
    listAdministrativeAreaTypes: () => dispatch(listAdministrativeAreaTypes()),
    listAdministrativeAreasReset: () => dispatch(listAdministrativeAreasReset()),
    listAdministrativeAreaTypesReset: () => dispatch(listAdministrativeAreaTypesReset()),
    administrativeAreasDetailsReset: () => dispatch(administrativeAreasDetailsReset()),
  };
};

// The component uses the redux store
export default connect(mapStateToProps, mapDispatchToProps)(WfpManagerAdministrativeAreasView);

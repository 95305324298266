import { resourceVersion } from "./resourceVersion";
import { resourceDetail, resourceDetailReset } from "./resourceDetail";
import { resourceList, resourceListReset } from "./resourceList";
import { resourceAdd, resourceAddReset } from "./resourceAdd";
import { resourceDelete, resourceDeleteReset } from "./resourceDelete";
import { resourceUpdate, resourceUpdateReset } from "./resourceUpdate";
import { currentPage } from "./currentPage";
import { currentSort } from "./currentSort";

// List of URLs used to fetch resources
const API_URL_AGGREGATOR_ADD = "/api/aggregators/";
const API_URL_AGGREGATOR_ADMINISTRATOR_ADD = "/api/aggregator_administrators/";
const API_URL_AGGREGATOR_ADMINISTRATOR_DELETE =
  "/api/aggregator_administrators/";
const API_URL_AGGREGATOR_ADMINISTRATOR_DETAIL =
  "/api/aggregator_administrators/";
const API_URL_AGGREGATOR_ADMINISTRATOR_UPDATE =
  "/api/aggregator_administrators/";
const API_URL_AGGREGATOR_ADMINISTRATORS_LIST =
  "/api/aggregator_administrators/";
const API_URL_AGGREGATOR_CREATION_REQUEST_LIST =
  "/api/aggregator_creation_requests/";
const API_URL_AGGREGATOR_CREATION_REQUEST_ADD =
  "/api/aggregator_creation_requests/";
const API_URL_AGGREGATOR_CREATION_REQUEST_DETAIL =
  "/api/aggregator_creation_requests/";
const API_URL_AGGREGATOR_CREATION_REQUEST_APPROVE =
  "/api/aggregator_creation_requests/";
const API_URL_AGGREGATOR_CREATION_REQUEST_REJECT =
  "/api/aggregator_creation_requests/";
const API_URL_AGGREGATOR_CREATION_REQUEST_UPDATE =
  "/api/aggregator_creation_requests/";
const API_URL_AGGREGATOR_CREATION_REQUEST_DELETE =
  "/api/aggregator_creation_requests/";
const API_URL_AGGREGATOR_DELETE = "/api/aggregators/";
const API_URL_AGGREGATOR_DETAIL = "/api/aggregators/";
const API_URL_AGGREGATOR_UPDATE = "/api/aggregators/";
const API_URL_AGGREGATORS_LIST = "/api/aggregators/";
const API_URL_AGGREGATORS_FOR_OFFER_LIST = "/api/aggregators_for_offer/";
const API_URL_COMMODITIES_LIST = "/api/commodities/";
const API_URL_COMMODITIES_LIST_ALL = "/api/commodities/everything/";
const API_URL_COUNTRIES_LIST = "/api/countries/";
const API_URL_COUNTRY_DASHBOARD = "/api/country_dashboard/";
const API_URL_COUNTRY_MCF_KPI_DASHBOARD = "/api/mcf_kpi_dashboard/";
const API_URL_CONFIGURATION = "/api/configuration/";
const API_URL_DEPOSITS_LIST = "/api/deposits/";
const API_URL_EXCHANGE_LOCATIONS = "/api/exchange_locations/";
const API_URL_EXCHANGE_LOCATION_TYPES = "/api/exchange_location_types/";
const API_URL_FARMERS_LIST = "/api/farmers/";
const API_URL_GROUPED_DEPOSITS_LIST =
  "/api/deposits/aggregated_for_bulk_offer/";
const API_URL_HELP_MESSAGE_DETAIL = "/api/help_messages/by_name/";
const API_URL_HELP_MESSAGE_LIST = "/api/help_messages/";
const API_URL_LANGUAGES_LIST = "/api/textlanguages/";
const API_URL_MANAGERS = "/api/wfp_managers/";
const API_URL_MARKET_OFFER_ADD = "/api/trader_market_offers/";
const API_URL_MARKET_OFFER_DETAIL = "/api/trader_market_offers/";
const API_URL_MARKET_OFFER_LIST = "/api/trader_market_offers/";
const API_URL_MARKET_ORDERS_LIST = "/api/aggregator_market_orders/with_offer/";
const API_URL_PRODUCTS_LIST = "/api/products/";
const API_URL_OFFER_COMMENTS = "/api/trader_market_offer_comments/";
const API_URL_ORG_LEGAL_STATUSES =
  "/api/aggregator_organization_legal_registration_statuses/";
const API_URL_ORG_TYPES = "/api/aggregator_organization_types/";
const API_URL_RECALL_MARKET_OFFER = "/api/trader_market_offer_recalls/";
const API_URL_REJECT_MARKET_OFFER = "/api/trader_market_offer_rejections/";
const API_URL_RECALL_REASON = "/api/trader_market_offer_recall_reasons/";
const API_URL_SEND_SMS_BULK = "/api/send_sms_bulk/";
const API_URL_TRADER_ADD = "/api/trader_organizations/";
const API_URL_TRADER_DELETE = "/api/trader_organizations/";
const API_URL_TRADER_DETAIL = "/api/trader_organizations/";
const API_URL_TRADER_LIST = "/api/trader_organizations/";
const API_URL_TRADER_LOGO_ADD = "/api/trader_organizations/";
const API_URL_TRADER_REPRESENTATIVE_ADD = "/api/trader_representatives/";
const API_URL_TRADER_REPRESENTATIVE_DELETE = "/api/trader_representatives/";
const API_URL_TRADER_REPRESENTATIVE_DETAIL = "/api/trader_representatives/";
const API_URL_TRADER_REPRESENTATIVE_LIST = "/api/trader_representatives/";
const API_URL_TRADER_REPRESENTATIVE_UPDATE = "/api/trader_representatives/";
const API_URL_TRADER_UPDATE = "/api/trader_organizations/";
const API_URL_UNITS_OF_MEASURE_LIST = "/api/units_of_measurement/";
const API_URL_UPDATE_FARMER_ADD = "/api/aggregators/";
const API_URL_USER_DETAIL = "/api/user/";
const API_URL_LATEST_USER_ACTIVITY = "/api/latest_user_activity/";
export const API_URL_USER_LIST = "/api/users/";
const API_URL_CREATE_MARKET_ORDER = "/api/aggregator_market_orders/";
const API_URL_ADMINISTRATIVE_AREA_TYPES = "api/administrative_area_types/";
const API_URL_ADMINISTRATIVE_AREAS = "api/administrative_areas/";
const API_URL_DEPOSIT_DELETION_REASONS = "api/deposit_deletion_reasons/";
const API_URL_FARMER_IDENTIFICATION = "api/identification_types/";
const API_URL_FARMER_DELETION_REASONS = "api/farmer_deletion_reasons/";
const API_URL_COMMODITY_QUALITY_GRADES = "api/quality_grades/";
const API_URL_TRADER_MARKET_OFFER_NEGOTIATION_PROPOSAL_REASONS =
  "/api/trader_market_offer_negotiation_proposal_reasons/";
const API_URL_TRADER_MARKET_OFFER_REJECTION_REASONS =
  "/api/trader_market_offer_rejection_reasons/";
const API_URL_PAYMENT_METHODS = "api/payment_methods/";
const API_URL_AGGREGATOR_UMBRELLA_ORGANIZATIONS =
  "api/aggregator_umbrella_organizations/";

export const API_URL_DEPOSIT_DETAILS = "/api/deposits/";

export const API_URL_ACCEPT_TERMS_AS_TRADER_REPRESENTATIVE =
  "/api/terms_and_conditions_consent/user_accepts/";
export const API_URL_CIAM_USER_CREATE = "/api/ciam_user_create/";

export const API_URL_NEGOTIATIONS =
  "/api/trader_market_offer_negotiationproposals/";

export const API_URL_TEXT_TEMPLATE = "api/text_templates/";
export const API_URL_NOTIFICATION_EVENTS = "api/notification_events/";
export const API_URL_TERMS_OF_USE = "api/terms_and_conditions/";
export const API_URL_INFOHUB_SUBJECTS = "api/infohub_subjects/";
export const API_URL_INFOHUB_TOPICS = "api/infohub_topics/";
export const API_URL_INFOHUB_SUBJECTS_ADD_AGGREGATOR =
  "api/infohub_subject_assignments/";
export const API_URL_INFOHUB_SUBJECTS_DELETE_AGGREGATOR =
  "api/infohub_subject_assignments/bulk_delete/";

export const API_URL_EXTERNAL_USER = "api/external_users/";
// List of actions available
export const addAggregator = resourceAdd("AGGREGATOR", API_URL_AGGREGATOR_ADD);
export const addAggregatorAdministrator = resourceAdd(
  "AGGREGATOR_ADMINISTRATOR",
  API_URL_AGGREGATOR_ADMINISTRATOR_ADD,
);
export const addAggregatorAdministratorReset = resourceAddReset(
  "AGGREGATOR_ADMINISTRATOR",
);
export const addAggregatorCreationRequest = resourceAdd(
  "AGGREGATOR_CREATION_REQUEST",
  API_URL_AGGREGATOR_CREATION_REQUEST_ADD,
);
export const addAggregatorCreationRequestReset = resourceAddReset(
  "AGGREGATOR_CREATION_REQUEST",
);
export const addAggregatorReset = resourceAddReset("AGGREGATOR");
export const addCommodity = resourceAdd(
  "COMMODITIES",
  API_URL_COMMODITIES_LIST,
  { page_size: "9999" },
);
export const addManager = resourceAdd("MANAGERS", API_URL_MANAGERS);
export const addMarketOffer = resourceAdd(
  "MARKET_OFFER",
  API_URL_MARKET_OFFER_ADD,
);
export const addMarketOfferRecall = resourceAdd(
  "RECALL_MARKET_OFFER",
  API_URL_RECALL_MARKET_OFFER,
);
export const addMarketOfferReset = resourceAddReset("MARKET_OFFER");
export const addOfferComment = resourceAdd(
  "OFFER_COMMENTS",
  API_URL_OFFER_COMMENTS,
);
export const addTrader = resourceAdd("PARTNER", API_URL_TRADER_ADD);
export const addTraderRepresentative = resourceAdd(
  "PARTNER_REPRESENTATIVE",
  API_URL_TRADER_REPRESENTATIVE_ADD,
);
export const addTraderRepresentativeReset = resourceAddReset(
  "PARTNER_REPRESENTATIVE",
);
export const addTraderReset = resourceAddReset("PARTNER");
export const aggregatorAdministratorDetails = resourceDetail(
  "AGGREGATOR_ADMINISTRATOR",
  API_URL_AGGREGATOR_ADMINISTRATOR_DETAIL,
);
export const aggregatorAdministratorDetailsReset = resourceDetailReset(
  "AGGREGATOR_ADMINISTRATOR",
);
export const aggregatorAdministratorsCurrentPage = currentPage(
  "AGGREGATOR_ADMINISTRATORS",
);
export const aggregatorCreationRequestApprove = resourceUpdate(
  "AGGREGATOR_CREATION_REQUEST_APPROVE",
  API_URL_AGGREGATOR_CREATION_REQUEST_APPROVE,
);
export const aggregatorCreationRequestApproveReset = resourceUpdateReset(
  "AGGREGATOR_CREATION_REQUEST_APPROVE",
);
export const aggregatorCreationRequestCurrentPage = currentPage(
  "AGGREGATOR_CREATION_REQUESTS",
);
export const aggregatorCreationRequestDetails = resourceDetail(
  "AGGREGATOR_CREATION_REQUEST",
  API_URL_AGGREGATOR_CREATION_REQUEST_DETAIL,
);
export const aggregatorCreationRequestDetailsReset = resourceDetail(
  "AGGREGATOR_CREATION_REQUEST",
);
export const aggregatorCreationRequestReject = resourceUpdate(
  "AGGREGATOR_CREATION_REQUEST_REJECT",
  API_URL_AGGREGATOR_CREATION_REQUEST_REJECT,
);
export const aggregatorCreationRequestRejectReset = resourceUpdateReset(
  "AGGREGATOR_CREATION_REQUEST_REJECT",
);
export const aggregatorDetails = resourceDetail(
  "AGGREGATOR",
  API_URL_AGGREGATOR_DETAIL,
);
export const aggregatorDetailsReset = resourceDetailReset("AGGREGATOR");

export const listAggregatorUmbrellaOrganizations = resourceList(
  "AGGREGATOR_UMBRELLA_ORGANIZATION",
  API_URL_AGGREGATOR_UMBRELLA_ORGANIZATIONS,
);
export const listAggregatorUmbrellaOrganizationsReset = resourceListReset(
  "AGGREGATOR_UMBRELLA_ORGANIZATION",
);
export const addAggregatorUmbrellaOrganizationsDetails = resourceAdd(
  "AGGREGATOR_UMBRELLA_ORGANIZATION",
  API_URL_AGGREGATOR_UMBRELLA_ORGANIZATIONS,
);
export const updateAggregatorUmbrellaOrganizationsDetails = resourceUpdate(
  "AGGREGATOR_UMBRELLA_ORGANIZATION",
  API_URL_AGGREGATOR_UMBRELLA_ORGANIZATIONS,
);
export const aggregatorUmbrellaOrganizationsDetails = resourceDetail(
  "AGGREGATOR_UMBRELLA_ORGANIZATION",
  API_URL_AGGREGATOR_UMBRELLA_ORGANIZATIONS,
);
export const aggregatorUmbrellaOrganizationsDetailsReset = resourceDetailReset(
  "AGGREGATOR_UMBRELLA_ORGANIZATION",
);

export const aggregatorMarketOrdersCurrentPage = currentPage(
  "AGGREGATOR_MARKET_ORDERS",
);
export const aggregatorsCurrentPage = currentPage("AGGREGATORS");
export const applicationVersion = resourceVersion("APPLICATION");
export const commoditiesCurrentPage = currentPage("COMMODITIES");
export const countryDashboard = resourceDetail(
  "COUNTRY_DASHBOARD",
  API_URL_COUNTRY_DASHBOARD,
);
export const countryMcfKpiDashboard = resourceDetail(
  "COUNTRY_MCF_KPI_DASHBOARD",
  API_URL_COUNTRY_MCF_KPI_DASHBOARD,
);
export const createOfferNegotiationProposal = resourceAdd(
  "NEGOTIATIONS",
  API_URL_NEGOTIATIONS,
);
export const deleteAggregator = resourceDelete(
  "AGGREGATOR",
  API_URL_AGGREGATOR_DELETE,
);
export const deleteAggregatorAdministrator = resourceDelete(
  "AGGREGATOR_ADMINISTRATOR",
  API_URL_AGGREGATOR_ADMINISTRATOR_DELETE,
);
export const deleteAggregatorAdministratorReset = resourceDeleteReset(
  "AGGREGATOR_ADMINISTRATOR",
);
export const deleteAggregatorCreationRequest = resourceDelete(
  "AGGREGATOR_CREATION_REQUEST",
  API_URL_AGGREGATOR_CREATION_REQUEST_DELETE,
);
export const deleteAggregatorCreationRequestReset = resourceDeleteReset(
  "AGGREGATOR_CREATION_REQUEST",
);
export const deleteAggregatorReset = resourceDeleteReset("AGGREGATOR");
export const deleteManager = resourceDelete("MANAGERS", API_URL_MANAGERS);
export const deleteTrader = resourceDelete("PARTNER", API_URL_TRADER_DELETE);
export const deleteTraderRepresentative = resourceDelete(
  "PARTNER_REPRESENTATIVE",
  API_URL_TRADER_REPRESENTATIVE_DELETE,
);
export const deleteTraderRepresentativeReset = resourceDeleteReset(
  "PARTNER_REPRESENTATIVE",
);
export const deleteTraderReset = resourceDeleteReset("PARTNER");
export const depositsCurrentPage = currentPage("DEPOSITS");
export const depositsCurrentSort = currentSort("DEPOSITS");
export const farmersCurrentPage = currentPage("FARMERS");
export const getHelpMessage = resourceDetail(
  "HELP_MESSAGE",
  API_URL_HELP_MESSAGE_DETAIL,
  "helpMessage",
);
export const listAggregatorAdministrators = resourceList(
  "AGGREGATOR",
  API_URL_AGGREGATOR_ADMINISTRATORS_LIST,
);
export const listAggregatorAdministratorsReset =
  resourceListReset("AGGREGATOR");
export const listAggregatorCreationRequests = resourceList(
  "AGGREGATOR_CREATION_REQUESTS",
  API_URL_AGGREGATOR_CREATION_REQUEST_LIST,
);
export const listAggregatorCreationRequestsReset = resourceListReset(
  "AGGREGATOR_CREATION_REQUESTS",
);
export const listAggregatorMarketOrders = resourceList(
  "AGGREGATOR_MARKET_ORDERS",
  API_URL_MARKET_ORDERS_LIST,
);
export const listAggregatorMarketOrdersReset = resourceListReset(
  "AGGREGATOR_MARKET_ORDERS",
);
export const listAggregators = resourceList(
  "AGGREGATORS",
  API_URL_AGGREGATORS_LIST,
);
export const listAggregatorsReset = resourceListReset("AGGREGATORS");
export const listAggregatorsForOffer = resourceList(
  "AGGREGATORS_FOR_OFFER",
  API_URL_AGGREGATORS_FOR_OFFER_LIST,
);
export const listAggregatorsForOfferReset = resourceListReset(
  "AGGREGATORS_FOR_OFFER",
);
export const listCIAMUsersPendingCreation = resourceList(
  "CIAM_USERS",
  "/api/ciam_users_pending_creation/",
); // TODO: extract to const
export const listCIAMUsersPendingCreationReset =
  resourceListReset("CIAM_USERS");
export const listCommodities = resourceList(
  "COMMODITIES",
  API_URL_COMMODITIES_LIST,
  { page_size: "9999" },
);
export const listAllCommodities = resourceList(
  "COMMODITIES",
  API_URL_COMMODITIES_LIST_ALL,
  { page_size: "9999" },
);
export const listCommoditiesReset = resourceListReset("COMMODITIES");
export const listCountries = resourceList("COUNTRIES", API_URL_COUNTRIES_LIST);
export const listConfiguration = resourceList(
  "CONFIGURATION",
  API_URL_CONFIGURATION,
);
export const listDeposits = resourceList("DEPOSITS", API_URL_DEPOSITS_LIST);
export const listDepositsReset = resourceListReset("DEPOSITS");
export const listExchangeLocations = resourceList(
  "EXCHANGE_LOCATIONS",
  API_URL_EXCHANGE_LOCATIONS,
);

export const listExchangeLocationTypes = resourceList(
  "EXCHANGE_LOCATION_TYPES",
  API_URL_EXCHANGE_LOCATION_TYPES,
);
export const listExchangeLocationTypesReset = resourceListReset(
  "EXCHANGE_LOCATION_TYPES",
);
export const addExchangeLocationsDetails = resourceAdd(
  "EXCHANGE_LOCATIONS",
  API_URL_EXCHANGE_LOCATIONS,
);
export const updateExchangeLocationsDetails = resourceUpdate(
  "EXCHANGE_LOCATIONS",
  API_URL_EXCHANGE_LOCATIONS,
);
export const deleteExchangeLocationsDetails = resourceDelete(
  "EXCHANGE_LOCATIONS",
  API_URL_EXCHANGE_LOCATIONS,
);
export const exchangeLocationsDetails = resourceDetail(
  "EXCHANGE_LOCATIONS",
  API_URL_EXCHANGE_LOCATIONS,
);
export const exchangeLocationsDetailsReset =
  resourceDetailReset("EXCHANGE_LOCATIONS");

export const listExchangeLocationsReset =
  resourceListReset("EXCHANGE_LOCATIONS");

export const listTermsAndConditions = resourceList(
  "TERMS_OF_USE",
  API_URL_TERMS_OF_USE,
);
export const listTermsAndConditionsText = resourceList(
  "TERMS_OF_USE",
  API_URL_TERMS_OF_USE,
);
export const addTermsAndConditionsTextDetails = resourceAdd(
  "TERMS_OF_USE",
  API_URL_TERMS_OF_USE,
);
export const updateTermsAndConditionsTextDetails = resourceUpdate(
  "TERMS_OF_USE",
  API_URL_TERMS_OF_USE,
);
export const deleteTermsAndConditionsTextDetails = resourceDelete(
  "TERMS_OF_USE",
  API_URL_TERMS_OF_USE,
);
export const termsAndConditionsTextDetails = resourceDetail(
  "TERMS_OF_USE",
  API_URL_TERMS_OF_USE,
);
export const termsAndConditionsTextDetailsReset =
  resourceDetailReset("TERMS_OF_USE");
export const listTermsAndConditionsTextReset =
  resourceListReset("TERMS_OF_USE");
export const listFarmers = resourceList("FARMERS", API_URL_FARMERS_LIST);
export const listFarmersReset = resourceListReset("FARMERS");
export const listGroupedDeposits = resourceList(
  "GROUPED_DEPOSITS",
  API_URL_GROUPED_DEPOSITS_LIST,
);
export const listHelpMessages = resourceList(
  "HELP_MESSAGE",
  API_URL_HELP_MESSAGE_LIST,
);
export const listHelpMessagesReset = resourceListReset("HELP_MESSAGE");
export const addHelpMessagesDetails = resourceAdd(
  "HELP_MESSAGE",
  API_URL_HELP_MESSAGE_LIST,
);
export const deleteHelpMessagesDetails = resourceDelete(
  "HELP_MESSAGE",
  API_URL_HELP_MESSAGE_LIST,
);
export const helpMessagesDetails = resourceDetail(
  "HELP_MESSAGE",
  API_URL_HELP_MESSAGE_LIST,
);
export const helpMessagesDetailsReset = resourceDetailReset(
  API_URL_HELP_MESSAGE_LIST,
);
export const updateHelpMessagesDetails = resourceUpdate(
  "HELP_MESSAGE",
  API_URL_HELP_MESSAGE_LIST,
);

export const listLanguages = resourceList("LANGUAGES", API_URL_LANGUAGES_LIST, {
  page_size: "9999",
});
export const listManagers = resourceList("MANAGERS", API_URL_MANAGERS);
export const listManagersReset = resourceListReset("MANAGERS");
export const listMarketOffers = resourceList(
  "MARKET_OFFERS",
  API_URL_MARKET_OFFER_LIST,
);
export const listMarketOffersReset = resourceListReset("MARKET_OFFERS");
export const listMineAggregators = resourceList(
  "MINE_AGGREGATORS",
  API_URL_AGGREGATORS_LIST,
  { page_size: "9999" },
);
export const listOfferComments = resourceList(
  "OFFER_COMMENTS",
  API_URL_OFFER_COMMENTS,
);
export const listOfferCommentsReset = resourceListReset("OFFER_COMMENTS");
export const listOfferNegotiationProposals = resourceList(
  "NEGOTIATIONS",
  API_URL_NEGOTIATIONS,
);
export const listOfferNegotiationProposalsReset =
  resourceListReset("NEGOTIATIONS");
export const listOrgLegalStatuses = resourceList(
  "ORG_LEGAL_STATUSES",
  API_URL_ORG_LEGAL_STATUSES,
);
export const listOrgTypes = resourceList("ORG_TYPES", API_URL_ORG_TYPES);
export const listProducts = resourceList("PRODUCTS", API_URL_PRODUCTS_LIST);
export const listRecallReasons = resourceList(
  "RECALL_REASONS",
  API_URL_RECALL_REASON,
);
export const listRecallReasonsReset = resourceListReset("RECALL_REASONS");
export const recallReasonsDetailsReset = resourceDetailReset("RECALL_REASONS");
export const addRecallReasonsDetails = resourceAdd(
  "RECALL_REASONS",
  API_URL_RECALL_REASON,
);
export const recallReasonsDetails = resourceDetail(
  "RECALL_REASONS",
  API_URL_RECALL_REASON,
);

export const updateRecallReasonsDetails = resourceUpdate(
  "RECALL_REASONS",
  API_URL_RECALL_REASON,
);
export const deleteRecallReasonsDetails = resourceDelete(
  "RECALL_REASONS",
  API_URL_RECALL_REASON,
);
export const listRecalls = resourceList("RECALL", API_URL_RECALL_MARKET_OFFER);
export const listRejections = resourceList(
  "REJECTION",
  API_URL_REJECT_MARKET_OFFER,
);
export const listTraderRepresentativesReset = resourceListReset(
  "PARTNER_REPRESENTATIVES",
);
export const listTraderRepresentatives = resourceList(
  "PARTNER_REPRESENTATIVES",
  API_URL_TRADER_REPRESENTATIVE_LIST,
  { page_size: "9999" },
);
export const listTradersReset = resourceListReset("PARTNERS");
export const listTraders = resourceList("PARTNERS", API_URL_TRADER_LIST);
export const listUnitsOfMeasure = resourceList(
  "UNITS_OF_MEASURE",
  API_URL_UNITS_OF_MEASURE_LIST,
  { page_size: "9999" },
);
export const listUnitsOfMeasureReset = resourceListReset("UNITS_OF_MEASURE");

export const addUnitsOfMeasureDetails = resourceAdd(
  "UNITS_OF_MEASURE",
  API_URL_UNITS_OF_MEASURE_LIST,
);

export const updateUnitsOfMeasureDetails = resourceUpdate(
  "UNITS_OF_MEASURE",
  API_URL_UNITS_OF_MEASURE_LIST,
);

export const unitsOfMeasureDetails = resourceDetail(
  "UNITS_OF_MEASURE",
  API_URL_UNITS_OF_MEASURE_LIST,
);

export const unitsOfMeasureDetailsReset =
  resourceDetailReset("UNITS_OF_MEASURE");
export const managersCurrentPage = currentPage("MANAGERS");
export const marketOfferDetails = resourceDetail(
  "MARKET_OFFER",
  API_URL_MARKET_OFFER_DETAIL,
);
export const marketOffersCurrentPage = currentPage("MARKET_OFFERS");
export const resetHelpMessage = resourceDetailReset("HELP_MESSAGE");
export const sendBulkSms = resourceAdd("BULK_SMS", API_URL_SEND_SMS_BULK);
export const sendBulkSmsReset = resourceAddReset("BULK_SMS");
export const setTraderLogo = resourceAdd(
  "PARTNER_LOGO",
  API_URL_TRADER_LOGO_ADD,
);
export const setTraderLogoReset = resourceAddReset("PARTNER_LOGO");
export const traderDetails = resourceDetail("PARTNER", API_URL_TRADER_DETAIL);
export const traderDetailsReset = resourceDetailReset("PARTNER");
export const traderRepresentativeDetails = resourceDetail(
  "PARTNER_REPRESENTATIVE",
  API_URL_TRADER_REPRESENTATIVE_DETAIL,
);
export const traderRepresentativeDetailsReset = resourceDetailReset(
  "PARTNER_REPRESENTATIVE",
);
export const tradersCurrentPage = currentPage("PARTNERS");
export const treeAdministrativeAreas = resourceList(
  "ADMINISTRATIVE_AREAS",
  API_URL_COUNTRIES_LIST,
);
export const updateAggregator = resourceUpdate(
  "AGGREGATOR",
  API_URL_AGGREGATOR_UPDATE,
);
export const updateAggregatorAdministrator = resourceUpdate(
  "AGGREGATOR_ADMINISTRATOR",
  API_URL_AGGREGATOR_ADMINISTRATOR_UPDATE,
);
export const updateAggregatorAdministratorReset = resourceUpdateReset(
  "AGGREGATOR_ADMINISTRATOR",
);
export const updateAggregatorReset = resourceUpdateReset("AGGREGATOR");
export const updateAggregatorCreationRequest = resourceUpdate(
  "AGGREGATOR_CREATION_REQUEST",
  API_URL_AGGREGATOR_CREATION_REQUEST_UPDATE,
);
export const updateAggregatorCreationRequestReset = resourceUpdateReset(
  "AGGREGATOR_CREATION_REQUEST",
);
export const updateCommodity = resourceUpdate(
  "COMMODITIES",
  API_URL_COMMODITIES_LIST,
  true,
);
export const updateManager = resourceUpdate("MANAGERS", API_URL_MANAGERS);
export const updateMarketOffer = resourceUpdate(
  "MARKET_OFFERS",
  API_URL_MARKET_OFFER_LIST,
);
export const updateTrader = resourceUpdate("PARTNER", API_URL_TRADER_UPDATE);
export const updateTraderRepresentative = resourceUpdate(
  "PARTNER_REPRESENTATIVE",
  API_URL_TRADER_REPRESENTATIVE_UPDATE,
);
export const updateTraderRepresentativeReset = resourceUpdateReset(
  "PARTNER_REPRESENTATIVE",
);
export const updateTraderReset = resourceUpdateReset("PARTNER");
export const uploadFarmers = resourceAdd(
  "UPLOAD_FARMERS",
  API_URL_UPDATE_FARMER_ADD,
);
export const uploadFarmersReset = resourceAddReset("UPLOAD_FARMERS");
export const userDetail = resourceDetail(
  "USER",
  API_URL_USER_DETAIL,
  "userDetail",
);

export const listLatestUserActivity = resourceList(
  "LATEST_USER_ACTIVITY",
  API_URL_LATEST_USER_ACTIVITY,
);
export const listLatestUserActivityReset = resourceListReset(
  "LATEST_USER_ACTIVITY",
);

export const createDeposit = resourceAdd("DEPOSITS", API_URL_DEPOSITS_LIST);
export const updateDeposit = resourceUpdate("DEPOSITS", API_URL_DEPOSITS_LIST);

export const createMarketOrder = resourceUpdate(
  "MARKET_ORDER",
  API_URL_CREATE_MARKET_ORDER,
);

export const listAdministrativeAreaTypes = resourceList(
  "ADMINISTRATIVE_AREA_TYPES",
  API_URL_ADMINISTRATIVE_AREA_TYPES,
);
export const listAdministrativeAreaTypesReset = resourceListReset(
  "ADMINISTRATIVE_AREA_TYPES",
);

export const addAdministrativeAreaTypesDetails = resourceAdd(
  "ADMINISTRATIVE_AREA_TYPES",
  API_URL_ADMINISTRATIVE_AREA_TYPES,
);
export const updateAdministrativeAreaTypesDetails = resourceUpdate(
  "ADMINISTRATIVE_AREA_TYPES",
  API_URL_ADMINISTRATIVE_AREA_TYPES,
);
export const deleteAdministrativeAreaTypesDetails = resourceDelete(
  "ADMINISTRATIVE_AREA_TYPES",
  API_URL_ADMINISTRATIVE_AREA_TYPES,
);
export const administrativeAreaTypesDetails = resourceDetail(
  "ADMINISTRATIVE_AREA_TYPES",
  API_URL_ADMINISTRATIVE_AREA_TYPES,
);
export const administrativeAreaTypesDetailsReset = resourceDetailReset(
  "ADMINISTRATIVE_AREA_TYPES",
);

export const listAdministrativeAreas = resourceList(
  "ADMINISTRATIVE_AREAS",
  API_URL_ADMINISTRATIVE_AREAS,
);
export const listAdministrativeAreasReset = resourceListReset(
  "ADMINISTRATIVE_AREAS",
);
export const addAdministrativeAreasDetails = resourceAdd(
  "ADMINISTRATIVE_AREAS",
  API_URL_ADMINISTRATIVE_AREAS,
);
export const updateAdministrativeAreasDetails = resourceUpdate(
  "ADMINISTRATIVE_AREAS",
  API_URL_ADMINISTRATIVE_AREAS,
);
export const administrativeAreasDetails = resourceDetail(
  "ADMINISTRATIVE_AREAS",
  API_URL_ADMINISTRATIVE_AREAS,
);
export const administrativeAreasDetailsReset = resourceDetailReset(
  "ADMINISTRATIVE_AREAS",
  API_URL_ADMINISTRATIVE_AREAS,
);

export const listDepositDeletionReasons = resourceList(
  "DEPOSIT_DELETION_REASONS",
  API_URL_DEPOSIT_DELETION_REASONS,
);
export const listDepositDeletionReasonsReset = resourceListReset(
  "DEPOSIT_DELETION_REASONS",
);
export const depositDeletionReasonsDetail = resourceDetail(
  "DEPOSIT_DELETION_REASONS",
  API_URL_DEPOSIT_DELETION_REASONS,
);
export const depositDeletionReasonsDetailReset = resourceDetailReset(
  "DEPOSIT_DELETION_REASONS",
);
export const addDepositDeletionReasonsDetail = resourceAdd(
  "DEPOSIT_DELETION_REASONS",
  API_URL_DEPOSIT_DELETION_REASONS,
);
export const updateDepositDeletionReasonsDetail = resourceUpdate(
  "DEPOSIT_DELETION_REASONS",
  API_URL_DEPOSIT_DELETION_REASONS,
);
export const deleteDepositDeletionReasonsDetail = resourceDelete(
  "DEPOSIT_DELETION_REASONS",
  API_URL_DEPOSIT_DELETION_REASONS,
);

export const listFarmerIdentification = resourceList(
  "FARMER_IDENTIFICATION",
  API_URL_FARMER_IDENTIFICATION,
);
export const listFarmerIdentificationReset = resourceListReset(
  "FARMER_IDENTIFICATION",
);

export const farmerIdentificationDetails = resourceDetail(
  "FARMER_IDENTIFICATION",
  API_URL_FARMER_IDENTIFICATION,
);
export const farmerIdentificationDetailsReset = resourceDetailReset(
  "FARMER_IDENTIFICATION",
);

export const addFarmerIdentificationDetails = resourceAdd(
  "FARMER_IDENTIFICATION",
  API_URL_FARMER_IDENTIFICATION,
);
export const updateFarmerIdentificationDetails = resourceUpdate(
  "FARMER_IDENTIFICATION",
  API_URL_FARMER_IDENTIFICATION,
);

export const deleteFarmerIdentificationDetails = resourceDelete(
  "FARMER_IDENTIFICATION",
  API_URL_FARMER_IDENTIFICATION,
);

export const listFarmerDeletionReasons = resourceList(
  "FARMER_DELETION_REASONS",
  API_URL_FARMER_DELETION_REASONS,
);
export const listFarmerDeletionReasonsReset = resourceListReset(
  "FARMER_DELETION_REASONS",
);
export const farmerDeletionReasonsDetails = resourceDetail(
  "FARMER_DELETION_REASONS",
  API_URL_FARMER_DELETION_REASONS,
);
export const farmerDeletionReasonsDetailsReset = resourceDetailReset(
  "FARMER_DELETION_REASONS",
);
export const deleteFarmerDeletionReasonsDetails = resourceDelete(
  "FARMER_DELETION_REASONS",
  API_URL_FARMER_DELETION_REASONS,
);
export const addFarmerDeletionReasonsDetails = resourceAdd(
  "FARMER_DELETION_REASONS",
  API_URL_FARMER_DELETION_REASONS,
);
export const updateFarmerDeletionReasonsDetails = resourceUpdate(
  "FARMER_DELETION_REASONS",
  API_URL_FARMER_DELETION_REASONS,
);

export const listCommodityQualityGrades = resourceList(
  "COMMODITY_QUALITY_GRADES",
  API_URL_COMMODITY_QUALITY_GRADES,
);
export const listCommodityQualityGradesReset = resourceListReset(
  "COMMODITY_QUALITY_GRADES",
);
export const addCommodityQualityGradesDetails = resourceAdd(
  "COMMODITY_QUALITY_GRADES",
  API_URL_COMMODITY_QUALITY_GRADES,
);
export const deleteCommodityQualityGradesDetails = resourceDelete(
  "COMMODITY_QUALITY_GRADES",
  API_URL_COMMODITY_QUALITY_GRADES,
);
export const updateCommodityQualityGradesDetails = resourceUpdate(
  "COMMODITY_QUALITY_GRADES",
  API_URL_COMMODITY_QUALITY_GRADES,
);
export const commodityQualityGradesDetails = resourceDetail(
  "COMMODITY_QUALITY_GRADES",
  API_URL_COMMODITY_QUALITY_GRADES,
);
export const commodityQualityGradesDetailsReset = resourceDetailReset(
  "COMMODITY_QUALITY_GRADES",
);

export const listTraderMarketOfferNegotiationProposalReasons = resourceList(
  "TRADER_MARKER_OFFER_NEGOTIATION_PROPOSAL_REASONS",
  API_URL_TRADER_MARKET_OFFER_NEGOTIATION_PROPOSAL_REASONS,
);
export const listTraderMarketOfferNegotiationProposalReasonsReset =
  resourceListReset("TRADER_MARKER_OFFER_NEGOTIATION_PROPOSAL_REASONS");

export const traderMarketOfferNegotiationProposalReasonsDetails =
  resourceDetail(
    "TRADER_MARKER_OFFER_NEGOTIATION_PROPOSAL_REASONS",
    API_URL_TRADER_MARKET_OFFER_NEGOTIATION_PROPOSAL_REASONS,
  );
export const traderMarketOfferNegotiationProposalReasonsDetailsReset =
  resourceDetailReset("TRADER_MARKER_OFFER_NEGOTIATION_PROPOSAL_REASONS");
export const addTraderMarketOfferNegotiationProposalReasonsDetails =
  resourceAdd(
    "TRADER_MARKER_OFFER_NEGOTIATION_PROPOSAL_REASONS",
    API_URL_TRADER_MARKET_OFFER_NEGOTIATION_PROPOSAL_REASONS,
  );
export const updateTraderMarketOfferNegotiationProposalReasonsDetails =
  resourceUpdate(
    "TRADER_MARKER_OFFER_NEGOTIATION_PROPOSAL_REASONS",
    API_URL_TRADER_MARKET_OFFER_NEGOTIATION_PROPOSAL_REASONS,
  );
export const deleteTraderMarketOfferNegotiationProposalReasonsDetails =
  resourceDelete(
    "TRADER_MARKER_OFFER_NEGOTIATION_PROPOSAL_REASONS",
    API_URL_TRADER_MARKET_OFFER_NEGOTIATION_PROPOSAL_REASONS,
  );

export const listTraderMarketOfferRejectionReasons = resourceList(
  "TRADER_MARKET_OFFER_REJECTION_REASONS",
  API_URL_TRADER_MARKET_OFFER_REJECTION_REASONS,
);
export const listTraderMarketOfferRejectionReasonsReset = resourceListReset(
  "TRADER_MARKET_OFFER_REJECTION_REASONS",
);
export const addTraderMarketOfferRejectionReasonsDetails = resourceAdd(
  "TRADER_MARKET_OFFER_REJECTION_REASONS",
  API_URL_TRADER_MARKET_OFFER_REJECTION_REASONS,
);
export const deleteTraderMarketOfferRejectionReasonsDetails = resourceDelete(
  "TRADER_MARKET_OFFER_REJECTION_REASONS",
  API_URL_TRADER_MARKET_OFFER_REJECTION_REASONS,
);
export const updateTraderMarketOfferRejectionReasonsDetails = resourceUpdate(
  "TRADER_MARKET_OFFER_REJECTION_REASONS",
  API_URL_TRADER_MARKET_OFFER_REJECTION_REASONS,
);
export const traderMarketOfferRejectionReasonsDetails = resourceDetail(
  "TRADER_MARKET_OFFER_REJECTION_REASONS",
  API_URL_TRADER_MARKET_OFFER_REJECTION_REASONS,
);
export const traderMarketOfferRejectionReasonsDetailsReset =
  resourceDetailReset("TRADER_MARKET_OFFER_REJECTION_REASONS");

export const listPaymentMethods = resourceList(
  "PAYMENT_METHODS",
  API_URL_PAYMENT_METHODS,
);
export const listPaymentMethodsReset = resourceListReset("PAYMENT_METHODS");

export const paymentMethodsDetails = resourceDetail(
  "PAYMENT_METHODS",
  API_URL_PAYMENT_METHODS,
);
export const paymentMethodsDetailsReset =
  resourceDetailReset("PAYMENT_METHODS");

export const addPaymentMethodsDetails = resourceAdd(
  "PAYMENT_METHODS",
  API_URL_PAYMENT_METHODS,
);
export const updatePaymentMethodsDetails = resourceUpdate(
  "PAYMENT_METHODS",
  API_URL_PAYMENT_METHODS,
);

export const deletePaymentMethodsDetails = resourceDelete(
  "PAYMENT_METHODS",
  API_URL_PAYMENT_METHODS,
);

export const listNotificationTextTemplates = resourceList(
  "TEXT_TEMPLATE",
  API_URL_TEXT_TEMPLATE,
);

export const listNotificationTextTemplatesReset =
  resourceListReset("TEXT_TEMPLATE");

export const listNotificationEvents = resourceList(
  "NOTIFICATION_EVENTS",
  API_URL_NOTIFICATION_EVENTS,
);

export const listNotificationEventsReset = resourceListReset(
  "NOTIFICATION_EVENTS",
);

export const listCountryInfoHubSubjects = resourceList(
  "INFOHUB_SUBJECTS_COUNTRY",
  API_URL_INFOHUB_SUBJECTS,
  {},
  true,
);
export const listCountryInfoHubSubjectsReset = resourceListReset(
  "INFOHUB_SUBJECTS_COUNTRY",
);
export const listAllInfoHubSubjects = resourceList(
  "INFOHUB_SUBJECTS_ALL",
  API_URL_INFOHUB_SUBJECTS,
  {},
  true,
);
export const listAllInfoHubSubjectsReset = resourceListReset(
  "INFOHUB_SUBJECTS_ALL",
);

export const addInfoHubSubjectDetail = resourceAdd(
  "INFOHUB_SUBJECTS",
  API_URL_INFOHUB_SUBJECTS,
);
export const updateInfoHubSubjectDetail = resourceUpdate(
  "INFOHUB_SUBJECTS",
  API_URL_INFOHUB_SUBJECTS,
);
export const deleteInfoHubSubjectDetail = resourceDelete(
  "INFOHUB_SUBJECTS",
  API_URL_INFOHUB_SUBJECTS,
);
export const infoHubSubjectDetail = resourceDetail(
  "INFOHUB_SUBJECTS",
  API_URL_INFOHUB_SUBJECTS,
  undefined,
  true,
);
export const infoHubSubjectDetailReset =
  resourceDetailReset("INFOHUB_SUBJECTS");
export const infoHubAddAggregatorToSubject = resourceAdd(
  "INFOHUB_SUBJECTS_AGGREGATOR",
  API_URL_INFOHUB_SUBJECTS_ADD_AGGREGATOR,
);
export const infoHubDeleteAggregatorFromSubject = resourceDelete(
  "INFOHUB_SUBJECTS_AGGREGATOR",
  API_URL_INFOHUB_SUBJECTS_DELETE_AGGREGATOR,
);
export const listInfoHubSubjectAggregators = resourceList(
  "INFOHUB_SUBJECTS_AGGREGATOR",
  API_URL_INFOHUB_SUBJECTS_ADD_AGGREGATOR,
);
export const listInfoHubSubjectAggregatorsReset = resourceListReset(
  "INFOHUB_SUBJECTS_AGGREGATOR",
);
export const listInfoHubTopics = resourceList(
  "INFOHUB_TOPICS",
  API_URL_INFOHUB_TOPICS,
);
export const listInfoHubTopicsReset = resourceListReset("INFOHUB_TOPICS");

export const updateExternalUser = resourceUpdate(
  "EXTERNAL_USER",
  API_URL_EXTERNAL_USER,
);
export const addExternalUser = resourceAdd(
  "EXTERNAL_USER",
  API_URL_EXTERNAL_USER,
);
export const listExternalUsers = resourceList(
  "EXTERNAL_USER",
  API_URL_EXTERNAL_USER,
);
export const listExternalUsersReset = resourceListReset("EXTERNAL_USER");
export const externalUsersCurrentPage = currentPage("EXTERNAL_USER");
export const deleteExternalUser = resourceDelete(
  "EXTERNAL_USER",
  API_URL_EXTERNAL_USER,
);

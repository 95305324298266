import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { iconWfpLogoEmblem } from "@wfp/icons";

import Icon from "../../components/Icon";
import {
  getAccessTokenByUrl,
  getExpiresInByUrl,
  getIdTokenByUrl,
  getNonceByUrl,
  getUserInformation,
  setupClient,
} from "../../utils/authentication";
import style from "./style.scss";

// A page displayed when login callback is received
export const LoginCallback = ({ history }) => {

  useEffect(() => {
    try {
      const nonce = localStorage.getItem("nonce");
      const receivedNonce = getNonceByUrl(history.location.hash);
      const ciamIdToken = getIdTokenByUrl(history.location.hash);
      const ciamAccessToken = getAccessTokenByUrl(history.location.hash);
      const expiresIn = parseInt(getExpiresInByUrl(history.location.hash));
      if (ciamAccessToken && receivedNonce === nonce) {
        localStorage.setItem("ciam_id_token", ciamIdToken);
        localStorage.setItem("ciam_access_token", ciamAccessToken);
        const expiryDate = new Date();
        expiryDate.setSeconds(expiryDate.getSeconds() + expiresIn);
        localStorage.setItem("expiry_date", expiryDate);
        setupClient(ciamAccessToken);
        getUserInformation()
          .then(data => {
            localStorage.setItem("user_profile", data.user_profile);
            localStorage.setItem("wfp_manger_id", data.wfp_manager || undefined);
            localStorage.setItem("trader_organization_id", data.organization || undefined);
            if(data.user_profile === "guest") {
              history.push("/LoginForbiddenGuest");
            }else if(data.user_profile === "wfp_manager") {
              localStorage.setItem("countries", JSON.stringify(data.countries));
              const countryCode = localStorage.getItem("country_code");
              if (countryCode) {
                history.push("/");
              } else {
                history.push("/wfpManager/countrySelection");
              }
            } else {
              localStorage.setItem("country_code", data.country ? data.country.code : undefined);
              localStorage.setItem("countries", JSON.stringify([data.country]));
              history.push("/");
            }
          }).catch(error => console.log(error));
        localStorage.removeItem("nonce");
      }
    } catch (error) {
      console.log(error);
    }
  }, []);

  return (
    <div className={style.wrapper}>
      <Icon
        icon={iconWfpLogoEmblem}
        width="150"
        height="150"
        fill="#ffffff"
      />
    </div>
  );
};

LoginCallback.propTypes = {
  history: PropTypes.object.isRequired,
};

export default LoginCallback;

export enum UserGroup {
    SYSTEM_ADMINISTRATOR = "System Administrator",
    WFP_MANAGER = "WFP Manager",
    TRADER_REPR = "Trader Representative",
    AGGREGATOR_ADMIN = "Aggregator Administrator",
    GUEST = "Guest",
    EXTERNAL_USER = "External User",
}

export interface UserData {
    user_groups: UserGroup[]
}

export const isSystemAdministrator = (user: UserData): boolean => {
    if (!(user?.user_groups?.length)) return false;
    return user.user_groups.some(group => group === UserGroup.SYSTEM_ADMINISTRATOR);
};

export const isExternalUser = (user: UserData): boolean => {
    if (!(user?.user_groups?.length)) return false;
    return user.user_groups.some(group => group === UserGroup.EXTERNAL_USER || group === UserGroup.GUEST);
};

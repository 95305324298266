import React, { useMemo, useState } from "react";
import { reduxForm, Field } from "redux-form";
import _ from "lodash";
import PropTypes from "prop-types";

import Select from "../../../../components/Select";
import Input from "../../../../components/Input";
import FormWrapper from "../../../../components/FormWrapper";
import Module from "../../../../components/Module";
import Loading from "../../../../components/Loading";
import {
  displayErrorMessage,
  getErrors,
  isFutureDate,
  valueOrDefault,
} from "../../../../utils";
import {
  hasFilledRequiredFields,
  normalizeNumberDecimal,
} from "../../../../utils/reduxForm";
import { Grid, Row, Col } from "../../../../components/Grid";
import ButtonsWrapper from "../../../../components/ButtonsWrapper";
import Button from "../../../../components/Button";
import {
  DELIVERY_LOCATIONS,
  ORDER_TYPES_ARRAY,
  NEGOTIABLE_OPTIONS,
} from "../../../../constants";
import { withTranslation } from "react-i18next";
import { getLocalisedName, translateOptions } from "../../../../utils/i18n";
import AggregatorSelection from "../AggregatorSelection";
import ShowHideArea from "../../../../components/ShowHideArea";
import Spacer from "../../../../components/Spacer";
import { star } from "../../../../utils";
import style from "./style.scss";
import DatePicker from "../../../../components/DatePicker";
import DepositCardHeader from "../../../Deposits/DepositCardHeader";

// The list of columns related to
// selected aggregators and their properties
export const selectedAggregatorColumns = () => [
  {
    Header: "marketOfferDetails.form.column.aggregator",
    accessor: "name",
  },
  {
    Header: "marketOfferDetails.form.column.noOfFarmer",
    accessor: "total_no_of_farmers",
    width: 110,
  },
  {
    Header: "marketOfferDetails.form.column.physicalAddress",
    accessor: "physical_address",
  },
  {
    Header: "marketOfferDetails.form.column.deposits",
    accessor: "deposits",
  },
];

// A function to generate the select button
// in the select aggregator table
export const generateSelectButton = (
  onClickSelectAggregator,
  selectedAggregator,
  row,
) => {
  return (
    <Button
      kind="secondary"
      small
      disabled={selectedAggregator.id === row.original.id}
      onClick={() => onClickSelectAggregator(row)}
    >
      Select
    </Button>
  );
};

// The details of a Market Offer with the form to edit it
export const Form = ({
  isFetching,
  errorMessage,
  aggregators,
  commodities,
  commodityQualityGrades,
  unitsOfMeasure,
  onClickValidate,
  fieldErrors,
  trader,
  onSelectDeposit,
  isAggregatorsModalOpen,
  errorMessageAggregators,
  isFetchingAggregators,
  aggregatorsPage,
  onPageChangeAggregators,
  aggregatorsCount,
  selectedAggregator,
  onClickSelectAggregator,
  onClickRemoveAggregator,
  setUnitOfMeasurement,
  matchingDeposits,
  updateMatchingDeposits,
  areMatchingDepositsFetching,
  onClickSeeDeposits,
  formValues,
  customExchangeLocations,
  t,
  isValid,
  setOrderType,
  isRangeOrder,
  administrativeAreas,
  exchangeLocationTypes,
  // These props are used in the validate function, but not in the component.
  /* eslint-disable no-unused-vars */
  setIsValid,
  /* eslint-enable no-unused-vars */
}) => {
  if (isFetching) {
    return <Loading isVisible />;
  }

  if (errorMessage) {
    return <>{displayErrorMessage(errorMessage)}</>;
  }

  const [selectedUnit, selectUnit] = useState(undefined);
  const [selectedQuality, selectQuality] = useState(undefined);
  const [filteredUnits, filterUnits] = useState([]);
  const [selectedCommodity, selectCommodity] = useState(undefined);
  const [showMatchingDeposits, setShowMatchingDeposits] = useState(true);

  // The list of columns related to
  // available aggregators and their properties
  const availableAggregatorsColumns = (
    onClickSelectAggregator,
    selectedAggregator,
  ) => [
    {
      Header: "marketOfferDetails.form.column.aggregator",
      accessor: "name",
    },
    {
      Header: (
        <div className={style.headerComponent}>
          {t("marketOfferDetails.form.column.aggregatorLocation")}
        </div>
      ),
      accessor: "physical_address",
      width: 10,
      Cell: ({ row }) => {
        const locationID = row.original.location;
        const administrativeArea = administrativeAreas.find(areas => areas.id === locationID);
        const address = row.original.physical_address;
        const area = administrativeArea.name;
        let fullAddress = "";
        if(address)fullAddress = fullAddress + address;
        if(address && area) fullAddress = fullAddress + ",\n";
        if(area) fullAddress = fullAddress + area;
        return fullAddress;
      },
    },
    {
      Header: `${t("marketOfferDetails.form.column.noOfFarmerWith")}\n${formValues.buy_commodity.label}`,
      accessor: "number_of_farmers_that_grow_selected_commodity",
      alignCellRight: true,
      width: 0,
      Cell: ({value}) => {
        return value ? value : 0;
      },
    },
    {
      Header: t("marketOfferDetails.form.column.depositsFor", {quality: formValues.quality.label}),
      Cell: ({ row }) =>
      {
        const deposits = row.original.relevant_deposits;
        if(!deposits)return 0.0;
        const singleCommodity = deposits.length > 1 ? deposits[0] : undefined;
        if(!singleCommodity) return "0";
        const totalQuantity = deposits.reduce((acc, curr) => acc + Math.round(parseFloat(curr.quantity)), 0);
        return valueOrDefault(`${totalQuantity} ${singleCommodity.unit_of_measure.code} ${singleCommodity.commodity.name}`, 0.0);
      },
      alignCellRight: true,
      width: 50,
    },
    {
      Cell: ({ row }) =>
        generateSelectButton(onClickSelectAggregator, selectedAggregator, row),
      width: 80,
      alignCentre: true,
    },
  ];

  const onUnitChange = (event) => {
    selectUnit(event ? event.data : null);
  };

  const showIfAnswered = (fieldKey) => {
    return !!(formValues[fieldKey]);
  };

  const onCommodityChange = (event) => {
    const commodity = event ? event.data : null;
    selectCommodity(commodity);
    if (commodity && commodity.units && commodity.units.length) {
      filterUnits(
        unitsOfMeasure.filter((unit) =>
          commodity.units.some((u) => unit.value === u),
        ),
      );
      if (commodity.units.length === 1) {
        const automaticallySelectedUnit = unitsOfMeasure.find(
          (unit) => unit.value === commodity.units[0],
        );
        setUnitOfMeasurement({
          ...automaticallySelectedUnit,
          label: getLocalisedName(automaticallySelectedUnit.data),
        });
        selectUnit(automaticallySelectedUnit.data);
      }
    } else {
      filterUnits(unitsOfMeasure);
      setUnitOfMeasurement(undefined);
      selectUnit(undefined);
    }
    if(selectedQuality && commodity){
      updateMatchingDeposits(
        commodity.id,
        selectedQuality,
      );
    }
  };

  const deliveryLocationOptions = translateOptions(DELIVERY_LOCATIONS, t).map(location => {
    const deliveryLocation = location.value === "drop_at_trader"
      ? trader.physical_address || ""
      : selectedAggregator.physical_address || "";
    return {
      ...location,
      label: deliveryLocation ? `${location.label}: ${deliveryLocation}` : location.label,
      location: deliveryLocation,
    };
  });

  const formattedCustomExchangeLocations = customExchangeLocations.filter(location => {
    return !deliveryLocationOptions.find((option) => {
      const currentOptionAddress = option && option.location;
      if(currentOptionAddress){
        return currentOptionAddress == location.physical_address;
      }
      return false;
    });
  });

  const aggregatedDeliveryLocations = useMemo(() => {
    const customLocations = formattedCustomExchangeLocations.reduce((prev, item) => {

      const traderAssignment = item.assignments.find(assignment => assignment.trader_organization === trader.id);
      if (!traderAssignment) { return prev; }

      const location = administrativeAreas ? administrativeAreas.find(area => area.id === item.location) : undefined;

      return [...prev, ...traderAssignment.types.map((type) => {
        const exchangeLocationType = exchangeLocationTypes.find(t => t.id === type);
        return ({
          label: `${item.name}${exchangeLocationType ? ", "  + exchangeLocationType.name : ""}${location ? ", "  + location.name : ""}`,
          value: {
            deliveryLocationType: "custom_exchange_location",
            custom_delivery_location: item.id,
            custom_delivery_location_type: type,
          },
        });
      })];
    }, []);

    return [...deliveryLocationOptions, ...customLocations];
  }, [deliveryLocationOptions, formattedCustomExchangeLocations, trader, administrativeAreas, exchangeLocationTypes]);

  const commodityQualityGradesOptions = commodityQualityGrades.map((item) => ({label: item.name, value: item.code}));

  return (
    <>
      <Grid>
        <Row>
          <Col sm={12}>
            <Module
              title={t("marketOfferDetails.form.traderDemandDetails")}
              footer={
                <ButtonsWrapper>
                  <Button
                    disabled={!(selectedAggregator && selectedAggregator.id && isValid)}
                    onClick={onClickValidate}
                  >
                    {t("marketOfferDetails.form.createTraderDemand")}
                  </Button>
                </ButtonsWrapper>
              }
            >
              <FormWrapper>
                <Grid>
                  <Row>
                    <Col sm={12}>
                      <Field
                        component={Select}
                        name="buy_commodity"
                        id="buy_commodity"
                        label={star(t("marketOfferDetails.form.commodityLabel"))}
                        options={
                          commodities &&
                          commodities.map((c) => ({
                            ...c,
                            label: getLocalisedName(c.data),
                          }))
                        }
                        onChange={onCommodityChange}
                        loading={false}
                        errorMessage={getErrors(fieldErrors, "buy_commodity")}
                      />
                    </Col>
                  </Row>
                </Grid>
                <ShowHideArea isVisible={
                  !!formValues.buy_commodity
                  && !!formValues.buy_commodity.value}
                >
                  <Grid>
                    <Row>
                      <Col sm={12}>
                        <Field
                          onChange={q => {
                            updateMatchingDeposits(
                              selectedCommodity && selectedCommodity.id,
                              q && q.value,
                            );
                            selectQuality(q && q.value);
                            setShowMatchingDeposits(true);
                          }}
                          component={Select}
                          name="quality"
                          id="quality"
                          label={star(t(
                            "marketOfferDetails.form.qualityLabel",
                            {
                              commodity: selectedCommodity
                                ? selectedCommodity.name
                                : t("marketOfferDetails.form.commodity"),
                            },
                          ))}
                          options={commodityQualityGradesOptions}
                          loading={false}
                          errorMessage={getErrors(fieldErrors, "quality")}
                        />
                      </Col>
                      {(
                        selectedCommodity &&
                        selectedCommodity.units &&
                        selectedCommodity.units.length !== 1
                      ) && (
                        <Col sm={12}>
                          <Field
                            component={Select}
                            name="unit_of_measure"
                            id="unit_of_measure"
                            label={star(t(
                              "marketOfferDetails.form.unitOfMeasureLabel",
                              {
                                commodity: selectedCommodity
                                  ? selectedCommodity.name
                                  : t("marketOfferDetails.form.commodity"),
                              },
                            ))}
                            options={filteredUnits.map((u) => ({
                              ...u,
                              label: getLocalisedName(u.data),
                            }))}
                            onChange={onUnitChange}
                            loading={false}
                            errorMessage={getErrors(fieldErrors, "unit_of_measure")}
                          />
                        </Col>
                      )}
                    </Row>
                  </Grid>
                  <ShowHideArea isVisible={!!formValues.quality && !!formValues.quality.value}>
                    <Grid>
                      {areMatchingDepositsFetching && <Loading isVisible/>}
                      {showMatchingDeposits && !!matchingDeposits.length && (
                        <Row>
                          <Col sm={12}>
                            <Module
                              title={t("marketOfferDetails.form.matchingDeposits")}
                              footer={
                                <div className={style.center}>
                                  <Button onClick={onClickSeeDeposits}>
                                    {t("marketOfferDetails.form.takeToMarket")}</Button>
                                  <Spacer height="0.75rem" />
                                  <Button kind="secondary" onClick={() => {
                                    setShowMatchingDeposits(false);
                                  }}
                                  >{t("marketOfferDetails.form.hide")}</Button>
                                </div>
                              }
                            >
                              {matchingDeposits.map((d) => (
                                <DepositCardHeader
                                  key={d.id}
                                  onMakeOffer={onSelectDeposit}
                                  deposit={d}
                                />
                              ))
                              }
                            </Module>
                          </Col>
                        </Row>
                      )}
                      <Row>
                        {(
                          !showMatchingDeposits
                          || (!isFetching && matchingDeposits.length === 0)
                        )
                        && showIfAnswered("unit_of_measure")
                        && (
                          <Col sm={12}>
                            <Field
                              placeholder={t("marketOfferDetails.form.selectOption")}
                              component={Select}
                              name="order_type"
                              id="order_type"
                              label={star(t("marketOfferDetails.form.orderTypeLabel"))}
                              options={translateOptions(ORDER_TYPES_ARRAY, t)}
                              loading={false}
                              errorMessage={getErrors(fieldErrors, "order_type")}
                              onChange={setOrderType}
                            />
                          </Col>
                        )}
                        <Col sm={12}>
                          {showIfAnswered("order_type") && (
                            <Field
                              component={Input}
                              name="minimum_quantity"
                              id="minimum_quantity"
                              label={
                                isRangeOrder
                                  ? star(t(
                                    "marketOfferDetails.form.minQuantityLabel",
                                    {
                                      commodity: selectedCommodity
                                        ? selectedCommodity.name
                                        : t("marketOfferDetails.form.commodity"),
                                    },
                                  ))
                                  : star(t("marketOfferDetails.form.quantity"))
                              }
                              normalize={normalizeNumberDecimal}
                              errorMessage={getErrors(
                                fieldErrors,
                                "minimum_quantity",
                              )}
                            />
                          )}
                        </Col>
                        {isRangeOrder && (
                          <Col sm={12}>
                            <Field
                              component={Input}
                              name="maximum_quantity"
                              id="maximum_quantity"
                              label={isRangeOrder
                                ? star(t(
                                  "marketOfferDetails.form.maxQuantityLabel",
                                  {
                                    commodity: selectedCommodity
                                      ? selectedCommodity.name
                                      : t("marketOfferDetails.form.commodity"),
                                  },
                                ))
                                : "ㅤ"
                              }
                              normalize={normalizeNumberDecimal}
                              errorMessage={getErrors(
                                fieldErrors,
                                "maximum_quantity",
                              )}
                            />
                          </Col>
                        )}
                      </Row>
                      <Row>
                        <Col sm={12}>
                          {showIfAnswered("minimum_quantity") && (
                            <Field
                              component={Input}
                              name="price"
                              id="price"
                              label={star(t(
                                "marketOfferDetails.form.price", {
                                  currency: trader.offers_currency && trader.offers_currency.symbol,
                                  unit: selectedUnit
                                    ? selectedUnit.code
                                    : "",
                                },
                              ))}
                              normalize={normalizeNumberDecimal}
                              errorMessage={getErrors(fieldErrors, "price")}
                            />
                          )}
                        </Col>
                      </Row>
                      <ShowHideArea isVisible={showIfAnswered("price")}>
                        <Field
                          component={Select}
                          name="negotiable"
                          id="negotiable"
                          label={star(t("marketOfferDetails.form.isNegotiable"))}
                          options={translateOptions(NEGOTIABLE_OPTIONS, t)}
                          loading={false}
                          errorMessage={getErrors(
                            fieldErrors,
                            "negotiable",
                          )}
                        />
                      </ShowHideArea>
                      <ShowHideArea isVisible={showIfAnswered("negotiable")}>
                        <Field
                          component={DatePicker}
                          withTime
                          name="exchange_date"
                          id="exchange_date"
                          label={star(t("marketOfferDetails.form.exchangeDate"))}
                          isOutsideRange={isFutureDate}
                          errorMessage={getErrors(fieldErrors, "exchange_date")}
                        />
                        <Field
                          component={Select}
                          name="delivery_location"
                          id="delivery_location"
                          label={star(t("marketOfferDetails.form.deliveryLocation"))}
                          // TODO: when api localises the model localise these
                          options={aggregatedDeliveryLocations}
                          loading={false}
                          errorMessage={getErrors(
                            fieldErrors,
                            "delivery_location",
                          )}
                        />
                        {trader.is_wfp_vendor && (
                          <Row>
                            <Col sm={12}>
                              <Field
                                component={Input}
                                name="contract_number"
                                id="contract_number"
                                label={t("marketOfferDetails.form.contractNumber")}
                                errorMessage={getErrors(fieldErrors, "contract_number")}
                              />
                            </Col>
                          </Row>
                        )}
                        <Row>
                          <Col sm={12}>
                            <Field
                              component={Input}
                              name="comment"
                              id="comment"
                              label={t("marketOfferDetails.form.comment")}
                              errorMessage={getErrors(fieldErrors, "comment")}
                            />
                          </Col>
                        </Row>
                      </ShowHideArea>
                    </Grid>
                    {showIfAnswered("delivery_location") && (
                      <AggregatorSelection
                        aggregators={aggregators}
                        aggregatorsCount={aggregatorsCount}
                        onPageChangeAggregators={onPageChangeAggregators}
                        onClickSelectAggregator={onClickSelectAggregator}
                        aggregatorsPage={aggregatorsPage}
                        isFetchingAggregators={isFetchingAggregators}
                        availableAggregatorsColumns={availableAggregatorsColumns}
                        selectedAggregator={selectedAggregator}
                        onClickRemoveAggregator={onClickRemoveAggregator}
                        isAggregatorsModalOpen={isAggregatorsModalOpen}
                        selectedCommodity={selectedCommodity}
                        errorMessageAggregators={errorMessageAggregators || (
                          Object.hasOwnProperty.call(fieldErrors, "tagged_aggregator") ? fieldErrors.tagged_aggregator.join(". ") : undefined
                        )}
                      />
                    )}
                  </ShowHideArea>
                </ShowHideArea>
              </FormWrapper>
            </Module>
          </Col>
        </Row>
      </Grid>
    </>
  );
};

// propTypes for the Form component
Form.propTypes = {
  isFetching: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string.isRequired,
  aggregators: PropTypes.array.isRequired,
  commodities: PropTypes.array.isRequired,
  unitsOfMeasure: PropTypes.array.isRequired,
  onClickValidate: PropTypes.func.isRequired,
  fieldErrors: PropTypes.object.isRequired,
  trader: PropTypes.object.isRequired,
  setIsValid: PropTypes.func.isRequired,
  isAggregatorsModalOpen: PropTypes.bool.isRequired,
  errorMessageAggregators: PropTypes.string.isRequired,
  isFetchingAggregators: PropTypes.bool.isRequired,
  isValid: PropTypes.bool.isRequired,
  isRangeOrder: PropTypes.bool.isRequired,
  aggregatorsPage: PropTypes.number.isRequired,
  onPageChangeAggregators: PropTypes.func.isRequired,
  aggregatorsCount: PropTypes.number.isRequired,
  selectedAggregator: PropTypes.object.isRequired,
  onClickSelectAggregator: PropTypes.func.isRequired,
  onClickRemoveAggregator: PropTypes.func.isRequired,
  setUnitOfMeasurement: PropTypes.func.isRequired,
  matchingDeposits: PropTypes.array.isRequired,
  onSelectDeposit: PropTypes.func.isRequired,
  updateMatchingDeposits: PropTypes.func.isRequired,
  onClickSeeDeposits: PropTypes.func.isRequired,
  areMatchingDepositsFetching: PropTypes.bool.isRequired,
  formValues: PropTypes.object.isRequired,
  setOrderType: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  customExchangeLocations: PropTypes.array.isRequired,
  administrativeAreas: PropTypes.array,
  exchangeLocationTypes: PropTypes.array.isRequired,
  commodityQualityGrades: PropTypes.array,
};

// defaultProps for the Form component
Form.defaultProps = {
  errorMessageMarketOffer: "",
};

const requiredFields = [
  "buy_commodity",
  "quality",
  "unit_of_measure",
  "order_type",
  "minimum_quantity",
  "price",
  "negotiable",
  "exchange_date",
  "delivery_location",
];

const validate = (values, props) => {
  const errors = {};

  // Check quantities are valid.
  const minimum_quantity = parseFloat(values.minimum_quantity);
  const maximum_quantity = parseFloat(values.maximum_quantity);

  if (props.isRangeOrder) {
    if (
      maximum_quantity
      && minimum_quantity
      && maximum_quantity < minimum_quantity
    ) {
      errors.minimum_quantity = props.t("marketOfferDetails.form.minQuantityLargerThanMax");
      errors.maximum_quantity = props.t("marketOfferDetails.form.maxQuantitySmallerThanMin");
    }
    if (!maximum_quantity > 0) {
      errors.maximum_quantity = props.t("directOfferDetails.form.depositNonZero");
    }
    if (maximum_quantity > 99999.99999) {
      errors.maximum_quantity = props.t("marketOfferDetails.form.quantityIsTooLarge");
    }
  }
  if (!minimum_quantity > 0) {
    errors.minimum_quantity = props.t("directOfferDetails.form.depositNonZero");
  }
  if (minimum_quantity > 99999.99999) {
    errors.minimum_quantity = props.t("marketOfferDetails.form.quantityIsTooLarge");
  }

  // Check required fields.
  const reqFields = props.isRangeOrder
    ? [...requiredFields, "maximum_quantity"]
    : [...requiredFields];

  hasFilledRequiredFields(reqFields, values, errors);

  props.setIsValid(_.isEmpty(errors));

  return errors;
};

export default reduxForm({
  form: "marketOfferDetails",
  enableReinitialize: true,
  validate,
})(withTranslation()(Form));

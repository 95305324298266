import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { v4 as uuidv4 } from "uuid";

import {
  listCommodities,
  addTrader,
  addTraderReset,
  setTraderLogo,
  setTraderLogoReset,
  traderDetails,
  traderDetailsReset,
  updateTrader,
  updateTraderReset,
  listTraderRepresentatives,
  listTraderRepresentativesReset,
  addTraderRepresentative,
  addTraderRepresentativeReset,
  updateTraderRepresentative,
  updateTraderRepresentativeReset,
  deleteTraderRepresentative,
  deleteTraderRepresentativeReset,
  traderRepresentativeDetails,
  traderRepresentativeDetailsReset,
  listExchangeLocations,
  listExchangeLocationsReset,
  listExchangeLocationTypes,
  listExchangeLocationTypesReset,
  listAdministrativeAreas,
} from "../../actions";
import PageStandard from "../../components/PageStandard";
import { LEGAL_STATUSES, CARD_TYPES, GENDERS } from "../../constants";
import {
  getStatusErrorMessage,
  isEmpty,
  valueOrDefault,
} from "../../utils";
import {
  adaptAdministrativeAreas,
  adaptCardTypes,
  adaptCommodities,
  adaptGenders,
  adaptLanguageForInitialValues,
  adaptLegalStatuses,
  adaptLocationForInitialValues,
  adaptPhoneNumber,
  adaptTraderRepresentatives,
  removeCountryCode,
} from "../../utils/adapters";
import { orderByLocalName } from "../../utils/i18n";
import Form from "./components/Form";

// A function to adapt values entered in form to a format accepted by the server
export const adaptFromValues = formValues => (
  {
    name: valueOrDefault(formValues.name, undefined),
    wings_id: valueOrDefault(formValues.wings_id, undefined),
    is_wfp_vendor: valueOrDefault(formValues.is_wfp_vendor, false),
    other_info: valueOrDefault(formValues.other_info, undefined),
    contact_email: valueOrDefault(formValues.contact_email, undefined),
    phone: adaptPhoneNumber(formValues.phone),
    legal_address: valueOrDefault(formValues.legal_address, undefined),
    physical_address: valueOrDefault(formValues.physical_address, undefined),
    district: valueOrDefault(formValues.district, undefined),
    location: valueOrDefault(formValues.location && formValues.location.value ?
      formValues.location.value : formValues.location , undefined),
    legal_status:
      valueOrDefault(formValues.legal_status && formValues.legal_status.value, undefined),
    year_of_establishment: valueOrDefault(formValues.year_of_establishment, undefined),
    bank_account:
      valueOrDefault(formValues.bank_account, undefined),
    provided_services:
      valueOrDefault(
        formValues.provided_services &&
        formValues.provided_services.map(service => service.value),
        [],
      ),
    commodities:
      valueOrDefault(
        formValues.commodities &&
        formValues.commodities.map(commodity => commodity.value),
        [],
      ),
    available_exchange_locations:
      valueOrDefault(
        formValues.available_exchange_locations &&
        formValues.available_exchange_locations.map(location => ({
          exchange_location: location.id,
          types: location.list,
        })),
        [],
      ),
  }
);

// A function to adapt values entered in form to a format accepted by the server
export const adaptRepresentativeFromValues = (formValues, organization) => (
  {
    person: {
      uuid: valueOrDefault(formValues.uuid, uuidv4()),
      first_name: valueOrDefault(formValues.first_name, undefined),
      last_name: valueOrDefault(formValues.last_name, undefined),
      contact_phone: adaptPhoneNumber(formValues.contact_phone),
    },
    email: valueOrDefault(formValues.email, undefined),
    organization: organization,
  }
);

// A function to adapt values received from server to
// a format compatible with the form
export const adaptInitialFormValues = (data, administrativeAreas) => valueOrDefault(data &&
  {
    ...data,
    language: adaptLanguageForInitialValues(data.language),
    location: adaptLocationForInitialValues(data, administrativeAreas),
    available_exchange_locations: data.available_exchange_locations ? data.available_exchange_locations.map((location) => ({
      id: location.exchange_location,
      list: location.types,
    })) : [],
  }
, {});

// A function to adapt values received from server to
// a format compatible with the form
export const adaptRepresentativeInitialFromValues = data => valueOrDefault(data && data.person &&
  {
    uuid: valueOrDefault(data.person && data.person.uuid, uuidv4()),
    first_name: valueOrDefault(data.person && data.person.first_name, undefined),
    middle_name: valueOrDefault(data.person && data.person.middle_name, undefined),
    last_name: valueOrDefault(data.person && data.person.last_name, undefined),
    contact_phone:
      valueOrDefault(data.person && removeCountryCode(data.person.contact_phone), undefined),
    location: valueOrDefault(data.person && data.person.location, undefined),
    identification_card_number:
      valueOrDefault(
        data.person &&
        data.person.identification_card_number,
        undefined,
      ),
    gender:
      valueOrDefault(
        data.person &&
        data.person.gender &&
        adaptCardTypes(GENDERS)
          .find(gender => data.person.gender === gender.value),
        undefined,
      ),
    day_of_birth:
      valueOrDefault(moment(new Date(data.person.day_of_birth)), undefined),
    email: valueOrDefault(data.email, undefined),
    position: valueOrDefault(data.position, undefined),
  }
, {});

// A function to adapt field errors for Representative
// record to a flat object
export const adaptRepresentativeFieldErrors = error => {
  if (isEmpty(error)) {
    return {};
  }

  if (Object.hasOwnProperty.call(error, "data")) {
    return valueOrDefault({
      ...error.data.person,
      ...error.data,
    }, {});
  }

  return valueOrDefault({
    ...error.person,
    ...error,
  }, {});

};

// This component is used to display the detail of a Trader
export const TraderDetails = (
  {
    isFetching,
    errorMessage,
    listCommodities,
    commodities,
    fieldErrors,
    addTrader,
    addTraderReset,
    history,
    formValues,
    legalStatuses,
    id,
    traderDetails,
    traderDetailsReset,
    updateTrader,
    updateTraderReset,
    initialFormValues,
    listTraderRepresentatives,
    listTraderRepresentativesReset,
    addTraderRepresentative,
    addTraderRepresentativeReset,
    updateTraderRepresentative,
    updateTraderRepresentativeReset,
    deleteTraderRepresentative,
    traderRepresentatives,
    isFetchingRepresentative,
    errorMessageRepresentative,
    traderRepresentativeDetails,
    traderRepresentativeDetailsReset,
    fieldErrorsRepresentative,
    cardTypes,
    genders,
    initialFormValuesRepresentative,
    isOnEditRepresentative,
    isFetchingDeleteRepresentative,
    errorMessageDeleteRepresentative,
    deleteTraderRepresentativeReset,
    listAdministrativeAreas,
    administrativeAreas,
    listExchangeLocations,
    listExchangeLocationsReset,
    isFetchingExchangeLocations,
    exchangeLocations,
    listExchangeLocationTypes,
    listExchangeLocationTypesReset,
    exchangeLocationTypes,
  },
) => {
  const [inEditRepresentative, setInEditRepresentative] = useState(undefined);
  const [isDeleteRepresentativeModalOpen, setIsDeleteRepresentativeModalOpen] = useState(false);
  const [currentToDeleteRepresentative, setCurrentToDeleteRepresentative] = useState(undefined);

  // hidding exchange location assigning feature for countries other than BD
  const hasCustomExchangeLocationFeatureEnabled = useRef(false);
  useEffect(() => {
    const countryCode = localStorage.getItem("selected_country");
    hasCustomExchangeLocationFeatureEnabled.current = countryCode === "BD";
  }, []);

  const { t } = useTranslation();

  const initialStep = history.location.state && history.location.state.fromStep;

  // On component mount the data are fetched or read on component unmount data
  // are reset
  useEffect(() => {
    if (id) {
      traderDetails(id);
      listTraderRepresentatives(id);
    }
    listCommodities();
    listExchangeLocations();
    listExchangeLocationTypes();
    listAdministrativeAreas();

    return () => {
      addTraderReset();
      updateTraderReset();
      setTraderLogoReset();
      traderDetailsReset();
      listTraderRepresentativesReset();
      addTraderRepresentativeReset();
      updateTraderRepresentativeReset();
      traderRepresentativeDetailsReset();
      deleteTraderRepresentativeReset();
      listExchangeLocationsReset();
      listExchangeLocationTypesReset();
    };
  }, []);

  // On click validate a requests are made to save Trader and logo, after
  // saving the traders list is displayed
  const onClickValidateAndClose = (formValues) => saveOrganization(true, formValues);

  // On click save and continue editing the record of Organization is saved
  // and the user is then redirected to edit page
  const onClickValidateAndContinue = (formValues, currentStep) => saveOrganization(false, formValues, currentStep);

  // This function is used to save a Trader Organization
  const saveOrganization = (close, formValues, currentStep) => {
    const redirect = id => {
      if (close) {
        history.push("/wfpManager/traders");
      } else {
        history.push(`/wfpManager/traderDetails/${id}`, { fromStep: currentStep ? currentStep + 1 : 1 });
        history.go();
      }
    };

    const data = adaptFromValues(formValues);
    const promise = id
      ? updateTrader(id, data)
      : addTrader(data);
    promise.then(result => {
      if (result) {
        redirect(result.data.id);
      }
    });
  };

  const saveExchangeLocations = (exchangeLocationsForm) => {
    const redirect = () => {
      history.push("/wfpManager/traders");
      history.go();
    };

    const data = adaptFromValues(exchangeLocationsForm);
    const promise = updateTrader(id, data);
    promise.then(result => {
      if (result) {
        redirect(result.data.id);
      }
    });
  };

  // On click open Trader Representative modal the modal is displayed
  const onClickAddRepresentative = () => {
    setInEditRepresentative(undefined);
  };

  // On click close Trader Representative modal the modal is closed
  const onClickBackRepresentativeForm = () => {
    setInEditRepresentative(undefined);
    traderRepresentativeDetailsReset();
  };

  const onClickValidateRepresentativeForm = (formValuesRepresentative) => {

    const reset = () => {
      listTraderRepresentatives(id);
      setInEditRepresentative(undefined);
      history.push(`/wfpManager/traderDetails/${id}`, { fromStep: 1 });
      history.go();
    };
    const values = adaptRepresentativeFromValues(formValuesRepresentative, id);

    if (inEditRepresentative) {
      updateTraderRepresentative(inEditRepresentative, values).then(result => result && reset());
    } else {
      addTraderRepresentative(values).then(result => result && reset());
    }

  };

  // On click edit on Trader Representative the modal is displayed to allow
  // the user modify the record
  const onClickEditRepresentative = record => {
    setInEditRepresentative(record.id);
    traderRepresentativeDetails(record.id);
  };

  // When user clicks edit Trader, it is redirected to Trader edit page
  const onClickDeleteRepresentative = record => {
    setIsDeleteRepresentativeModalOpen(true);
    setCurrentToDeleteRepresentative(record);
  };

  // When user confirm that it want to delete the record the record is actually
  // deleted
  const onClickConfirmDeleteRepresentative = () => {
    deleteTraderRepresentative(currentToDeleteRepresentative.id).then(result => {
      if (result) {
        setIsDeleteRepresentativeModalOpen(false);
        setCurrentToDeleteRepresentative(undefined);
        listTraderRepresentatives(id);
        deleteTraderRepresentativeReset();
      }
    });
  };

  // When user press cancel on delete modal the modal is closed and the current
  // record to delete is reset
  const onClickCancelDeleteRepresentative = () => {
    setIsDeleteRepresentativeModalOpen(false);
    setCurrentToDeleteRepresentative(undefined);
    deleteTraderRepresentativeReset();
  };

  return (
    <PageStandard title={t("traderDetails.title")}>
      <Form
        id={id}
        initialValues={initialFormValues}
        isFetching={isFetching}
        isFetchingExchangeLocations={isFetchingExchangeLocations}
        errorMessage={errorMessage}
        commodities={commodities}
        fieldErrors={fieldErrors}
        onClickValidateAndClose={onClickValidateAndClose}
        onClickValidateAndContinue={onClickValidateAndContinue}
        onClickAddRepresentative={onClickAddRepresentative}
        traderRepresentatives={traderRepresentatives}
        legalStatuses={legalStatuses}
        onClickEditRepresentative={onClickEditRepresentative}
        onClickDeleteRepresentative={onClickDeleteRepresentative}
        isDeleteRepresentativeModalOpen={isDeleteRepresentativeModalOpen}
        onClickConfirmDeleteRepresentative={onClickConfirmDeleteRepresentative}
        onClickCancelDeleteRepresentative={onClickCancelDeleteRepresentative}
        currentToDeleteRepresentative={currentToDeleteRepresentative}
        isFetchingDeleteRepresentative={isFetchingDeleteRepresentative}
        errorMessageDeleteRepresentative={errorMessageDeleteRepresentative}
        administrativeAreas={administrativeAreas}
        formValues={formValues}
        initialStep={initialStep}
        initialFormValuesRepresentative={initialFormValuesRepresentative}
        isFetchingRepresentative={isFetchingRepresentative}
        errorMessageRepresentative={errorMessageRepresentative}
        onClickValidateRepresentativeForm={onClickValidateRepresentativeForm}
        onClickValidateExchangeLocationsForm={saveExchangeLocations}
        fieldErrorsRepresentative={fieldErrorsRepresentative}
        cardTypes={cardTypes}
        genders={genders}
        onClickBackRepresentativeForm={onClickBackRepresentativeForm}
        isOnEditRepresentative={isOnEditRepresentative}
        exchangeLocations={exchangeLocations}
        exchangeLocationTypes={exchangeLocationTypes}
        hasCustomExchangeLocationFeatureEnabled={hasCustomExchangeLocationFeatureEnabled.current}
      />
    </PageStandard>
  );
};

// propTypes for the TraderDetails component
TraderDetails.propTypes = {
  isFetching: PropTypes.bool.isRequired,
  isFetchingExchangeLocations: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string.isRequired,
  listCommodities: PropTypes.func.isRequired,
  commodities: PropTypes.array.isRequired,
  fieldErrors: PropTypes.object.isRequired,
  addTrader: PropTypes.func.isRequired,
  addTraderReset: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  formValues: PropTypes.object.isRequired,
  legalStatuses: PropTypes.array.isRequired,
  id: PropTypes.string.isRequired,
  traderDetails: PropTypes.func.isRequired,
  traderDetailsReset: PropTypes.func.isRequired,
  updateTrader: PropTypes.func.isRequired,
  updateTraderReset: PropTypes.func.isRequired,
  initialFormValues: PropTypes.object.isRequired,
  listTraderRepresentatives: PropTypes.func.isRequired,
  listTraderRepresentativesReset: PropTypes.func.isRequired,
  addTraderRepresentative: PropTypes.func.isRequired,
  addTraderRepresentativeReset: PropTypes.func.isRequired,
  updateTraderRepresentative: PropTypes.func.isRequired,
  updateTraderRepresentativeReset: PropTypes.func.isRequired,
  deleteTraderRepresentative: PropTypes.func.isRequired,
  traderRepresentatives: PropTypes.array.isRequired,
  isFetchingRepresentative: PropTypes.bool.isRequired,
  errorMessageRepresentative: PropTypes.string.isRequired,
  traderRepresentativeDetails: PropTypes.func.isRequired,
  traderRepresentativeDetailsReset: PropTypes.func.isRequired,
  fieldErrorsRepresentative: PropTypes.object.isRequired,
  cardTypes: PropTypes.array.isRequired,
  genders: PropTypes.array.isRequired,
  initialFormValuesRepresentative: PropTypes.object.isRequired,
  isOnEditRepresentative: PropTypes.bool.isRequired,
  isFetchingDeleteRepresentative: PropTypes.bool.isRequired,
  errorMessageDeleteRepresentative: PropTypes.string.isRequired,
  deleteTraderRepresentativeReset: PropTypes.func.isRequired,
  listAdministrativeAreas: PropTypes.func.isRequired,
  administrativeAreas: PropTypes.array.isRequired,
  listExchangeLocations: PropTypes.func.isRequired,
  listExchangeLocationsReset: PropTypes.func.isRequired,
  exchangeLocations: PropTypes.array.isRequired,
  listExchangeLocationTypes: PropTypes.func.isRequired,
  listExchangeLocationTypesReset: PropTypes.func.isRequired,
  exchangeLocationTypes: PropTypes.array.isRequired,
};

// Starting from the redux state it gets data related to logged in user
export const mapStateToProps = (state, props) => {
  const administrativeAreasTree = adaptAdministrativeAreas(
    state.listAdministrativeAreas.data.results,
  );

  return {
    // Current on edit trader
    id: valueOrDefault(props.match.params.id, ""),
    initialFormValues: adaptInitialFormValues(
      state.traderDetails.data,
      administrativeAreasTree,
    ),
    // Loading and errors properties
    isFetching:
      state.listCommodities.isFetching ||
      state.setTraderLogo.isLoading ||
      state.addTrader.isLoading ||
      state.updateTrader.isLoading ||
      state.traderDetails.isFetching ||
      state.listTraderRepresentatives.isFetching ||
      state.listAdministrativeAreas.isFetching,
    isFetchingExchangeLocations:
      state.listExchangeLocations.isFetching ||
      state.listExchangeLocations.isResetting ||
      state.listExchangeLocationTypes.isFetching ||
      state.listExchangeLocationTypes.isResetting,
    errorMessage:
      state.listCommodities.errorMessage ||
      state.listTraderRepresentatives.errorMessage ||
      state.traderDetails.errorMessage ||
      getStatusErrorMessage(state.setTraderLogo.error) ||
      getStatusErrorMessage(state.addTrader.error) ||
      getStatusErrorMessage(state.updateTrader.error) ||
      state.listAdministrativeAreas.error ||
      "",
    // Related entities lists
    commodities: adaptCommodities(orderByLocalName(state.listCommodities.data.results)),
    legalStatuses: adaptLegalStatuses(LEGAL_STATUSES),
    cardTypes: adaptCardTypes(CARD_TYPES),
    genders: adaptGenders(GENDERS),
    // Form errors in Trader Organization form
    fieldErrors:
      !isEmpty(state.addTrader.error) && state.addTrader.error ||
      !isEmpty(state.updateTrader.error) && state.updateTrader.error ||
      {},
    // Trader Representatives list properties
    traderRepresentatives:
      adaptTraderRepresentatives(state.listTraderRepresentatives.data.results),
    // Properties related to Representative modal
    isFetchingRepresentative:
      state.addTraderRepresentative.isLoading ||
      state.updateTraderRepresentative.isLoading ||
      state.traderRepresentativeDetails.isFetching,
    errorMessageRepresentative:
      getStatusErrorMessage(state.addTraderRepresentative.error) ||
      getStatusErrorMessage(state.updateTraderRepresentative.error) ||
      "",
    // Form errors in Trader Representative form
    fieldErrorsRepresentative:
      adaptRepresentativeFieldErrors(state.addTraderRepresentative.error) ||
      adaptRepresentativeFieldErrors(state.updateTraderRepresentative.error) ||
      {},
    // Current on edit Trader Representative
    initialFormValuesRepresentative:
      adaptRepresentativeInitialFromValues(state.traderRepresentativeDetails.data),
    // Current on edit Representative
    isOnEditRepresentative: state.traderRepresentativeDetails.data.id !== undefined,
    // Delete Representative properties
    isFetchingDeleteRepresentative: state.deleteTraderRepresentative.isLoading,
    errorMessageDeleteRepresentative: state.deleteTraderRepresentative.errorMessage || "",
    administrativeAreas: administrativeAreasTree,
    exchangeLocations: state.listExchangeLocations.data.results,
    exchangeLocationTypes: state.listExchangeLocationTypes.data.results,
  };
};

// Maps functions to dispatch actions
export const mapDispatchToProps = dispatch => {
  return {
    listCommodities: () => dispatch(listCommodities({ page_size: "9999" })),
    addTrader: data => dispatch(addTrader(data)),
    addTraderReset: () => dispatch(addTraderReset()),
    updateTrader: (id, data) => dispatch(updateTrader(id, data)),
    updateTraderReset: () => dispatch(updateTraderReset()),
    setTraderLogo: (data, appendUrl) => dispatch(setTraderLogo(data, appendUrl)),
    setTraderLogoReset: () => dispatch(setTraderLogoReset()),
    traderDetails: id => dispatch(traderDetails(id)),
    traderDetailsReset: () => dispatch(traderDetailsReset()),
    listTraderRepresentatives:
      organization => dispatch(listTraderRepresentatives({ organization })),
    listTraderRepresentativesReset: () => dispatch(listTraderRepresentativesReset()),
    addTraderRepresentative: data => dispatch(addTraderRepresentative(data)),
    addTraderRepresentativeReset: () => dispatch(addTraderRepresentativeReset()),
    updateTraderRepresentative: (id, data) => dispatch(updateTraderRepresentative(id, data)),
    updateTraderRepresentativeReset: () => dispatch(updateTraderRepresentativeReset()),
    deleteTraderRepresentative: id => dispatch(deleteTraderRepresentative(id)),
    traderRepresentativeDetails: id => dispatch(traderRepresentativeDetails(id)),
    traderRepresentativeDetailsReset: () => dispatch(traderRepresentativeDetailsReset()),
    deleteTraderRepresentativeReset: () => dispatch(deleteTraderRepresentativeReset()),
    listAdministrativeAreas: () => dispatch(listAdministrativeAreas({page_size: 9999, page: 1})),
    listExchangeLocations: () => dispatch(listExchangeLocations()),
    listExchangeLocationsReset: () => dispatch(listExchangeLocationsReset()),
    listExchangeLocationTypes: () => dispatch(listExchangeLocationTypes()),
    listExchangeLocationTypesReset: () => dispatch(listExchangeLocationTypesReset()),
  };
};

// The component uses the redux store
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(TraderDetails));

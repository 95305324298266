import React from "react";
import { Link, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import {
  Breadcrumb as WFPBreadcrumb,
  BreadcrumbItem,
  BreadcrumbHome,
} from "@wfp/ui";
import { useTranslation } from "react-i18next";

// Builds a list of links starting from a given location
// the order of routes is important to get the right match
// using startsWith method
const routes = (t) => [
  {
    value: "/representative/representativeAggregatorCreationRequestView",
    title: t("representativeAggregatorCreationRequestDetails.title"),
  },
  {
    value: "/representative/representativeAggregatorCreationRequestDetails",
    title: t("representativeAggregatorCreationRequestDetails.title"),
  },
  {
    value: "/representative/representativeAggregatorCreationRequests",
    title: t("aggregatorCreationRequests.title"),
  },
  {
    value: "/representative/marketOfferSms",
    title: t("marketOfferSms.title"),
  },
  {
    value: "/wfpManager/wfpManagerAggregatorCreationRequests",
    title: t("aggregatorCreationRequests.title"),
  },
  {
    value: "/wfpManager/wfpManagerAggregatorView",
    title: t("aggregatorView.title"),
  },
  {
    value: "/representative/representativeAggregatorView",
    title: t("aggregatorView.title"),
  },
  {
    value: "/wfpManager/aggregatorDetails",
    title: t("aggregatorDetails.pageTitle"),
  },
  {
    value: "/wfpManager/aggregatorDetails",
    title: t("aggregatorDetails.pageTitle"),
  },
  {
    value: "/wfpManager/traderDetails",
    title: t("traderView.title"),
  },
  {
    value: "/wfpManager/traderView",
    title: t("traderView.title"),
  },
  {
    value: "/representative/marketOfferView",
    title: t("marketOfferView.title"),
  },
  {
    value: "/representative/market",
    title: t("farmersMarket.title"),
  },
  {
    value: "/representative/marketOfferDetails",
    title: t("marketOfferDetails.title"),
  },
  {
    value: "/wfpManager/marketOfferView",
    title: t("marketOfferView.title"),
  },
  {
    value: "/wfpManager/aggregators",
    title: t("aggregators.pageTitle"),
  },
  {
    value: "/wfpManager/commodities",
    title: t("commodities.title"),
  },
  {
    value: "/wfpManager/traders",
    title: t("traders.title"),
  },
  {
    value: "/wfpManager/managers",
    title: t("managers.pageTitle"),
  },
  {
    value: "/wfpManager/infohub/newContent",
    title: t("infoHub.newContent"),
  },
  {
    value: "/wfpManager/infohub",
    title: t("infoHub.title"),
  },
  {
    value: "/wfpManager/configuration/administrativeAreaTypes",
    title: t("configuration.item.administrativeAreaTypes"),
  },
  {
    value: "/wfpManager/configuration/administrativeAreas",
    title: t("configuration.item.administrativeAreas"),
  },
  {
    value: "/wfpManager/configuration/aggregatorOfferRejectionReasons",
    title: t("configuration.item.aggregatorOfferRejectionReasons"),
  },
  {
    value: "/wfpManager/configuration/commodities",
    title: t("configuration.item.commodities"),
  },
  {
    value: "/wfpManager/configuration/commodityQualityGrades",
    title: t("configuration.item.commodityQualityGrades"),
  },
  {
    value: "/wfpManager/configuration/commodityUnitsOfMeasure",
    title: t("configuration.item.commodityUnitsOfMeasure"),
  },
  {
    value: "/wfpManager/configuration/depositDeletionReasons",
    title: t("configuration.item.depositDeletionReasons"),
  },
  {
    value: "/wfpManager/configuration/exchangeLocations",
    title: t("configuration.item.exchangeLocations"),
  },
  {
    value: "/wfpManager/configuration/farmerDeletionReasons",
    title: t("configuration.item.farmerDeletionReasons"),
  },
  {
    value: "/wfpManager/configuration/farmerIdentification",
    title: t("configuration.item.farmerIdentification"),
  },
  {
    value: "/wfpManager/configuration/landUnitsOfMeasure",
    title: t("configuration.item.landUnitsOfMeasure"),
  },
  {
    value: "/wfpManager/configuration/paymentMethods",
    title: t("configuration.item.paymentMethods"),
  },
  {
    value: "/wfpManager/configuration/traderMarketOfferNegotiationProposalReasons",
    title: t("configuration.item.traderMarketOfferNegotiationProposalReasons"),
  },
  {
    value: "/wfpManager/configuration/recallReasons",
    title: t("configuration.item.traderOfferRecallReasons"),
  },
  {
    value: "/wfpManager/configuration/helpMessages",
    title: t("configuration.item.webHelpMessages"),
  },
  {
    value: "/wfpManager/configuration/systemNotifications",
    title: t("configuration.item.systemNotifications"),
  },
  {
    value: "/wfpManager/configuration/aggregatorUmbrellaOrganizations",
    title: t("configuration.item.aggregatorUmbrellaOrganizations"),
  },
  {
    value: "/wfpManager/configuration/termsAndConditionsText",
    title: t("configuration.item.termsAndConditionsText"),
  },
  {
    value: "/wfpManager/configuration",
    title: t("header.configuration"),
  },
  {
    value: "/wfpManager",
    title: t("home.manager.title"),
  },
  {
    value: "/guest",
    title: t("externalUsers.pageTitle"),
  },
  {
    value: "/representative",
    title: t("home.representative.title"),
  },
  {
    value: "/systemAdmin/platformAccess",
    title: t("platformAccess.title"),
  },
];

// Gets the current route starting from the current location
export const getLink = (pathname, t) => {
  const link = routes(t).find((route) => pathname.startsWith(route.value));
  if (link) {
    return (
      <BreadcrumbItem key={pathname}>
        <Link to={pathname}>{link.title}</Link>
      </BreadcrumbItem>
    );
  }
  return (
    <BreadcrumbItem key={pathname}>
      <Link to={pathname}>Not Found</Link>
    </BreadcrumbItem>
  );
};

// A breadcrumb component that allows user to navigate through application's pages
// This allows for more than one breadcrumb using a single path.
const Breadcrumb = ({ pathname }) => {
  const { t } = useTranslation();
  // Separate path into sections
  let splitPath = pathname.split(/(?=\/)/g);

  // aggregate all paths into a single list.
  const pathList = [];
  if(splitPath.length <= 2){
    pathList.push(getLink(pathname, t));
  }else{
    const allMatchedPaths = [];
    // Each path needs to be combined to the previous path to be matched to a route
    splitPath.map((_, index) => {
      if(index < 1 || Number.isInteger(parseInt(_.charAt(1))))return;

      // Add each section of the path to a single list.
      const concatCurrentPath = [];
      for(let i = 0; i <= index; i++){
        concatCurrentPath.push(splitPath[i]);
      }
      allMatchedPaths.push(concatCurrentPath.join(""));
    });
    allMatchedPaths.map((path) => {
      pathList.push(getLink(path, t));
    });
  }
  return (
    <WFPBreadcrumb>
      <BreadcrumbItem>
        <Link to="/">
          <BreadcrumbHome />
        </Link>
      </BreadcrumbItem>
      {
        pathList
      }
    </WFPBreadcrumb>
  );
};

// propTypes for the breadcrumb component
Breadcrumb.propTypes = {
  pathname: PropTypes.string.isRequired,
};

// Here we pass the pathname to the Breadcrumb component to allow it to render the right trail
export default withRouter((props) => (
  <Breadcrumb pathname={props.location.pathname} />
));

import React from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import PageStandard from "../../components/PageStandard";
import { getCountryObjectFromCache, valueOrDefault } from "../../utils";
import List from "./components/List";
import PropTypes from "prop-types";
import { listConfiguration } from "../../actions";
import { ContextMenu, ContextMenuGroup, ContextMenuItem } from "@wfp/ui";
import { languages } from "../../utils/i18n";
import Icon from "../../components/Icon";
import { iconLink } from "@wfp/icons";

const transifexLinks = (code, type) => {
  switch (type) {
  case "backend":
    return `https://app.transifex.com/un-wfp/farm2go-backend/translate/#${code}/farm2go_backend/414717885`;
  case "mobile":
    return `https://app.transifex.com/un-wfp/farm2go-mobile-app/translate/#${code}/Mobile_en/409966909`;
  case "web":
    return `https://app.transifex.com/un-wfp/farm2go-web/translate/#${code}/translation/418195449`;
  default:
    return null;
  }
};

const getTransifexLink = (type) => {
  const country = getCountryObjectFromCache().code;
  const language = languages.find((l) => l.flag === country);
  if (!language) return null;
  const code = language.value;
  return transifexLinks(code, type);
};

export const WfpManagerConfigurationView = ({
  isFetching,
  errorMessage,
  configurationItems,
  page,
  count,
  listConfiguration,
}) => {
  const { t } = useTranslation();

  // On component mount the data are fetched
  useEffect(() => {
    listConfiguration();
    return () => {};
  }, []);

  const transifexContextMenu = () => {
    return (
      <>
        <ContextMenu
          content={() => (
            <>
              <ContextMenuGroup>
                <ContextMenuItem
                  onClick={() =>
                    window.open(getTransifexLink("backend"), "_blank").focus()
                  }
                >
                  <div>{t("configuration.transifex.backend")}</div>
                </ContextMenuItem>
                <ContextMenuItem
                  onClick={() =>
                    window.open(getTransifexLink("mobile"), "_blank").focus()
                  }
                >
                  <div>{t("configuration.transifex.mobile")}</div>
                </ContextMenuItem>
                <ContextMenuItem
                  onClick={() =>
                    window.open(getTransifexLink("web"), "_blank").focus()
                  }
                >
                  <div>{t("configuration.transifex.web")}</div>
                </ContextMenuItem>
              </ContextMenuGroup>
            </>
          )}
        >
          <div
            className="wfp--btn wfp--btn--primary"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <div style={{ paddingRight: "1rem" }}>
              {t("configuration.transifex.title")}
            </div>
            <Icon fill={"#ffffff"} icon={iconLink} />
          </div>
        </ContextMenu>
      </>
    );
  };
  return (
    <PageStandard
      title={`${t("configuration.title")} ${getCountryObjectFromCache().name}`}
      additional={transifexContextMenu()}
    >
      <List
        {...{
          isFetching,
          errorMessage,
          configurationItems,
          page,
          count,
        }}
      />
    </PageStandard>
  );
};

WfpManagerConfigurationView.propTypes = {
  isFetching: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string.isRequired,
  configurationItems: PropTypes.array.isRequired,
  page: PropTypes.number.isRequired,
  count: PropTypes.number.isRequired,
  listConfiguration: PropTypes.func.isRequired,
};

export const mapStateToProps = (state) => {
  return {
    configurationItems: state.listConfiguration.data.results,
    isFetching: state.listConfiguration.isFetching,
    errorMessage: state.listConfiguration.errorMessage,
    page: 1,
    count: valueOrDefault(state.listConfiguration.length, 0),
  };
};

// Maps functions to dispatch actions
export const mapDispatchToProps = (dispatch) => {
  return {
    listConfiguration: () => dispatch(listConfiguration()),
  };
};

// The component uses the redux store
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(WfpManagerConfigurationView);

import React, { Suspense } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { Provider } from "react-redux";

import RepresentativeRoute from "../RepresentativeRoute";
import WfpManagerRoute from "../WfpManagerRoute";
import configureStore from "../../store";
import ErrorMessage from "../ErrorMessage";
import MarketOfferView from "../MarketOfferView";
import MarketOfferDetails from "../MarketOfferDetails";
import MarketOfferSms from "../MarketOfferSms";
import Login from "../Login";
import Logout from "../Logout";
import LoginCallback from "../LoginCallback";
import LogoutCallback from "../LogoutCallback";
import LoginRedirect from "../LoginRedirect";
import LoginForbidden from "../LoginForbidden";
import LoginForbiddenAdministrator from "../LoginForbiddenAdministrator";
import NotFound from "../NotFound";
import RepresentativeHome from "../RepresentativeHome";
import CountrySelection from "../CountrySelection";
import WfpManagerHome from "../WfpManagerHome";
import Aggregators from "../Aggregators";
import Deposits from "../Deposits";
import RepresentativeAggregatorCreationRequests from "../RepresentativeAggregatorCreationRequests";
import RepresentativeAggregatorCreationRequestView from "../RepresentativeAggregatorCreationRequestView";
import RepresentativeAggregatorCreationRequestDetails from "../RepresentativeAggregatorCreationRequestDetails";
import WfpManagerAggregatorCreationRequests from "../WfpManagerAggregatorCreationRequests";
import WfpManagerAggregatorCreationRequestView from "../WfpManagerAggregatorCreationRequestView";
import Traders from "../Traders";
import WfpManagerAggregatorView from "../WfpManagerAggregatorView";
import RepresentativeAggregatorView from "../RepresentativeAggregatorView";
import TraderDetails from "../TraderDetails";
import AggregatorDetails from "../AggregatorDetails";
import Commodities from "../Commodities";
import UpdateApplicationBanner from "../UpdateApplicationBanner";
import PlatformAccess from "../PlatformAccess";
import DirectOfferDetails from "../DirectOfferDetails";
import CommodityEdit from "../CommodityEdit";
import Managers from "../Managers";
import ManagerDetails from "../ManagerDetails";
import Profile from "../Profile";
import Configuration from "../WfpManagerConfigurationView";
import AdministrativeAreaTypes from "../WfpManagerAdministrativeAreaTypesView";
import WfpManagerCommodityUnitsOfMeasureView from "../WfpManagerCommodityUnitsOfMeasureView";
import WfpManagerAdministrativeAreasView from "../WfpManagerAdministrativeAreasView";
import WfpManagerCommodityQualityGradesView from "../WfpManagerCommodityQualityGradesView";
import WfpManagerDepositDeletionReasonsView from "../WfpManagerDepositDeletionReasonsView";
import WfpManagerExchangeLocationsView from "../WfpManagerExchangeLocationsView";
import WfpManagerFarmerDeletionReasonsView from "../WfpManagerFarmerDeletionReasonsView";
import WfpManagerFarmerIdentificationView from "../WfpManagerFarmerIdentificationView";
import WfpManagerHelpMessagesView from "../WfpManagerHelpMessagesView";
import WfpManagerLandUnitsOfMeasureView from "../WfpManagerLandUnitsOfMeasureView";
import WfpManagerPaymentMethodsView from "../WfpManagerPaymentMethodsView";
import WfpManagerRecallReasonsView from "../WfpManagerRecallReasonsView";
import WfpManagerTraderMarketOfferNegotiationProposalReasonsView from "../WfpManagerTraderMarketOfferNegotiationProposalReasonsView";
import WfpManagerAggregatorOfferRejectionReasonsView from "../WfpManagerAggregatorOfferRejectionReasonsView";
import WfpManagerSystemNotifications from "../WfpManagerSystemNotifications";
import WfpManagerAggregatorUmbrellaOrganizations from "../WfpManagerAggregatorUmbrellaOrganizations";
import InfoHub from "../InfoHub";
import EnvironmentIndicator from "../EnvironmentIndicator";
import WfpManagerTermsAndConditionsText from "../WfpManagerTermsAndConditionsText";
import InfoHubFormWrapper from "../InfoHub/Components/Form/InfoHubFormWrapper";
import ExternalUsers from "../ExternalUsers";
import ExternalUserDetails from "../ExternalUserDetails";
import GuestRoute from "../GuestRoute";
import LoginForbiddenGuest from "../LoginForbiddenGuest";
import AccountDeletion from "../AccountDeletion";
import LoggedOutRoute from "../LoggedOutRoute";
import AccountDeletionConfirmation from "../AccountDeletion/AccountDeletionConfirmation";
import AccountDeletionVerification from "../AccountDeletion/AccountDeletionVerification";
import AccountDeletionFeedback from "../AccountDeletion/AccountDeletionFeedback";
// Through the store we will share redux state to all component used in the application
const store = configureStore();

// The application root component is created, the Provider allow to share
// the redux store with all components used in the application
// and the BrowserRouter allow user to navigate through all the pages
const Application = () => {
  return (
    <Provider store={store}>
      <Suspense fallback="loading">
        <BrowserRouter>
          <UpdateApplicationBanner />
          <EnvironmentIndicator />
          <Switch>
            <LoggedOutRoute
              path="/accountDeletion/verify/:uuid"
              component={AccountDeletionVerification}
            />
            <LoggedOutRoute
              path="/accountDeletion/feedback"
              component={AccountDeletionFeedback}
            />
            <LoggedOutRoute
              path="/accountDeletion/confirmation"
              component={AccountDeletionConfirmation}
            />
            <LoggedOutRoute
              path="/accountDeletion"
              component={AccountDeletion}
            />
            <Route exact path="/login" component={Login} />
            <Route exact path="/logout" component={Logout} />
            <Route exact path="/errorMessage" component={ErrorMessage} />
            <Route exact path="/loginForbidden" component={LoginForbidden} />
            <Route
              exact
              path="/loginForbiddenAdministrator"
              component={LoginForbiddenAdministrator}
            />
            <Route
              exact
              path="/LoginForbiddenGuest"
              component={LoginForbiddenGuest}
            />
            <Route exact path="/ciam_callback/" component={LoginCallback} />
            <Route
              exact
              path="/ciam_logout_callback/"
              component={LogoutCallback}
            />
            <Route exact path="/" component={LoginRedirect} />

            <RepresentativeRoute
              exact
              path="/representative/"
              component={RepresentativeHome}
            />
            <RepresentativeRoute
              exact
              path="/representative/marketOfferView/:id"
              component={MarketOfferView}
            />
            <RepresentativeRoute
              exact
              path="/representative/marketOfferSms/:id"
              component={MarketOfferSms}
            />
            <RepresentativeRoute
              exact
              path="/representative/marketOfferDetails"
              component={MarketOfferDetails}
            />
            <RepresentativeRoute
              exact
              path="/representative/directOfferDetails"
              component={DirectOfferDetails}
            />
            <RepresentativeRoute
              exact
              path="/representative/representativeAggregatorCreationRequests"
              component={RepresentativeAggregatorCreationRequests}
            />
            <RepresentativeRoute
              exact
              path="/representative/representativeAggregatorCreationRequestView/:id"
              component={RepresentativeAggregatorCreationRequestView}
            />
            <RepresentativeRoute
              exact
              path="/representative/representativeAggregatorCreationRequestDetails"
              component={RepresentativeAggregatorCreationRequestDetails}
            />
            <RepresentativeRoute
              exact
              path="/representative/representativeAggregatorCreationRequestDetails/:id"
              component={RepresentativeAggregatorCreationRequestDetails}
            />
            <RepresentativeRoute
              exact
              path="/representative/representativeAggregatorView/:id"
              component={RepresentativeAggregatorView}
            />
            <RepresentativeRoute
              exact
              path="/representative/market"
              component={Deposits}
            />
            <RepresentativeRoute
              exact
              path="/representative/profile"
              component={Profile}
            />
            <WfpManagerRoute
              exact
              path="/wfpManager/managers"
              component={Managers}
            />
            <WfpManagerRoute
              exact
              path="/wfpManager/managerDetails/:id"
              component={ManagerDetails}
            />
            <WfpManagerRoute
              exact
              path="/wfpManager/externalUsers"
              component={ExternalUsers}
            />
            <WfpManagerRoute
              exact
              path="/wfpManager/externalUserDetails/:id"
              component={ExternalUserDetails}
            />
            <WfpManagerRoute
              fullScreen
              exact
              path="/wfpManager/CountrySelection"
              component={CountrySelection}
            />
            <WfpManagerRoute
              exact
              path="/wfpManager/"
              component={WfpManagerHome}
            />
            <GuestRoute exact path="/guest/" component={WfpManagerHome} />
            <WfpManagerRoute
              exact
              path="/wfpManager/marketOfferView/:id"
              component={MarketOfferView}
            />
            <WfpManagerRoute
              exact
              path="/wfpManager/aggregators"
              component={Aggregators}
            />
            <WfpManagerRoute
              exact
              path="/wfpManager/wfpManagerAggregatorView/:id"
              component={WfpManagerAggregatorView}
            />
            <WfpManagerRoute
              exact
              path="/wfpManager/aggregatorDetails"
              component={AggregatorDetails}
            />
            <WfpManagerRoute
              exact
              path="/wfpManager/aggregatorDetails/:id"
              component={AggregatorDetails}
            />
            <WfpManagerRoute
              exact
              path="/wfpManager/traders"
              component={Traders}
            />
            <WfpManagerRoute
              exact
              path="/wfpManager/commodities"
              component={Commodities}
            />
            <WfpManagerRoute
              exact
              path="/wfpManager/commodities/:id"
              component={CommodityEdit}
            />
            <WfpManagerRoute
              exact
              path="/wfpManager/traderDetails"
              component={TraderDetails}
            />
            <WfpManagerRoute
              exact
              path="/wfpManager/traderDetails/:id"
              component={TraderDetails}
            />
            <WfpManagerRoute
              exact
              path="/wfpManager/wfpManagerAggregatorCreationRequests"
              component={WfpManagerAggregatorCreationRequests}
            />
            <WfpManagerRoute
              exact
              path="/wfpManager/wfpManagerAggregatorCreationRequestView/:id"
              component={WfpManagerAggregatorCreationRequestView}
            />
            <WfpManagerRoute
              exact
              path="/systemAdmin/platformAccess"
              component={PlatformAccess}
            />
            <WfpManagerRoute
              exact
              path="/wfpManager/configuration"
              component={Configuration}
            />
            <WfpManagerRoute
              exact
              path="/wfpManager/configuration/paymentMethods"
              component={WfpManagerPaymentMethodsView}
            />
            <WfpManagerRoute
              exact
              path="/wfpManager/configuration/farmerIdentification"
              component={WfpManagerFarmerIdentificationView}
            />
            <WfpManagerRoute
              exact
              path="/wfpManager/configuration/farmerDeletionReasons"
              component={WfpManagerFarmerDeletionReasonsView}
            />
            <WfpManagerRoute
              exact
              path="/wfpManager/configuration/depositDeletionReasons"
              component={WfpManagerDepositDeletionReasonsView}
            />
            <WfpManagerRoute
              exact
              path="/wfpManager/configuration/aggregatorOfferRejectionReasons"
              component={WfpManagerAggregatorOfferRejectionReasonsView}
            />
            <WfpManagerRoute
              exact
              path="/wfpManager/configuration/recallReasons"
              component={WfpManagerRecallReasonsView}
            />
            <WfpManagerRoute
              exact
              path="/wfpManager/configuration/helpMessages"
              component={WfpManagerHelpMessagesView}
            />
            <WfpManagerRoute
              exact
              path="/wfpManager/configuration/exchangeLocations"
              component={WfpManagerExchangeLocationsView}
            />
            <WfpManagerRoute
              exact
              path="/wfpManager/configuration/commodityQualityGrades"
              component={WfpManagerCommodityQualityGradesView}
            />
            <WfpManagerRoute
              exact
              path="/wfpManager/configuration/administrativeAreas"
              component={WfpManagerAdministrativeAreasView}
            />
            <WfpManagerRoute
              exact
              path="/wfpManager/configuration/administrativeAreaTypes"
              component={AdministrativeAreaTypes}
            />
            <WfpManagerRoute
              exact
              path="/wfpManager/configuration/traderMarketOfferNegotiationProposalReasons"
              component={
                WfpManagerTraderMarketOfferNegotiationProposalReasonsView
              }
            />
            <WfpManagerRoute
              exact
              path="/wfpManager/configuration/landUnitsOfMeasure"
              component={WfpManagerLandUnitsOfMeasureView}
            />
            <WfpManagerRoute
              exact
              path="/wfpManager/configuration/commodityUnitsOfMeasure"
              component={WfpManagerCommodityUnitsOfMeasureView}
            />
            <WfpManagerRoute
              exact
              path="/wfpManager/configuration/systemNotifications"
              component={WfpManagerSystemNotifications}
            />
            <WfpManagerRoute
              exact
              path="/wfpManager/configuration/aggregatorUmbrellaOrganizations"
              component={WfpManagerAggregatorUmbrellaOrganizations}
            />
            <WfpManagerRoute
              exact
              path="/wfpManager/configuration/termsAndConditionsText"
              component={WfpManagerTermsAndConditionsText}
            />
            <WfpManagerRoute
              exact
              path="/wfpManager/infoHub"
              component={InfoHub}
            />
            <WfpManagerRoute
              exact
              path="/wfpManager/infohub/newContent"
              component={InfoHubFormWrapper}
            />

            <WfpManagerRoute
              exact
              path="/wfpManager/infohub/newContent/:id"
              component={InfoHubFormWrapper}
            />
            <Route component={NotFound} />
          </Switch>
        </BrowserRouter>
      </Suspense>
    </Provider>
  );
};

export default Application;

import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import * as Sentry from "@sentry/browser";
import { v4 as uuidv4 } from "uuid";

import {
  listTraders,
  listMarketOffers,
  marketOffersCurrentPage,
  API_URL_ACCEPT_TERMS_AS_TRADER_REPRESENTATIVE,
  addMarketOfferRecall,
  listMineAggregators,
  listCommodities,
  listUnitsOfMeasure,
  listRecalls,
  listRejections,
  listOfferComments,
  addOfferComment,
  listAggregatorAdministrators,
  updateMarketOffer,
  listOfferNegotiationProposals,
  createOfferNegotiationProposal,
  listExchangeLocations,
  listOfferCommentsReset,
  listOfferNegotiationProposalsReset,
  traderRepresentativeDetails,
  listAdministrativeAreas,
  listExchangeLocationTypes,
  userDetail,
  listTermsAndConditions,
} from "../../actions";
import client from "../../client";
import Button from "../../components/Button";
import ButtonsWrapper from "../../components/ButtonsWrapper";
import { Col, Grid, Row } from "../../components/Grid";
import PageStandard from "../../components/PageStandard";
import TermsAndConditionsModal from "../../components/TermsAndConditionsModal";
import { formatNumber, isEmpty, valueOrDefault } from "../../utils";
import { adaptMarketOffers, adaptTrader } from "../../utils/adapters";
import {
  getUserLanguage,
  onChangeLanguage,
  orderByLocalName,
} from "../../utils/i18n";
import { HelpContext } from "../HelpMessageRoute";
import MarketOffers from "./components/MarketOffers";
import Filters from "./components/Filters";
import { OfferStatus } from "./components/OfferCard/interfaces";
import { OFFER_TYPES } from "../../constants";

// The home representative page component
export const RepresentativeHome = ({
  addNegotiationProposal,
  administrativeAreas,
  aggregators,
  commodities,
  counterOfferFieldErrors,
  counterOfferFormData,
  currentUser,
  customExchangeLocations,
  exchangeLocationTypes,
  errorMessageMarketOffers,
  errorMessageTrader,
  history,
  isFetching,
  isFetchingMarketOffers,
  listAdministrativeAreas,
  listAggregatorAdministrators,
  listCommodities,
  listExchangeLocations,
  listExchangeLocationTypes,
  listMarketOffers,
  listMineAggregators,
  listTraders,
  listUnitsOfMeasure,
  marketOffers,
  marketOffersCount,
  marketOffersCurrentPage,
  marketOffersPage,
  recallOffer,
  recallOfferFormData,
  refreshContent,
  sendBackExchange,
  sendBackExchangeFormData,
  setOfferRecalledStatus,
  trader,
  traderRepresentative,
  traderRepresentativeDetails,
  unitsOfMeasure,
  fetchUserDetails,
  termsAndConditions,
  listTermsAndConditions,
}) => {
  const help = useContext(HelpContext);
  const [isBusy, setIsBusy] = useState(false);
  const [isTermsModalVisible, setTermsModalVisibility] = useState(false);
  const [filters, setFilters] = useState(
    (history.location && history.location.state) || {},
  );
  const [pagination, setPagination] = useState({
    page: 1,
    pageSize: 10,
  });
  const { t } = useTranslation();

  const selectedLanguage = getUserLanguage();

  // On page market offers page change
  const onPageChangeMarketOffers = (page) => {
    setPagination(page);
    marketOffersCurrentPage(page);
    listMarketOffers(page.page, page.pageSize, filters);
  };

  // On click view market offer the detail is displayed
  const onClickViewMarketOffer = (id) => {
    history.push(`/representative/marketOfferView/${id}`);
  };

  // A function to print the home page
  const onClickPrintHome = () => {
    window.print();
  };

  const onTermsSubmit = async () => {
    setIsBusy(true);
    try {
      await client.post(API_URL_ACCEPT_TERMS_AS_TRADER_REPRESENTATIVE);
      setTermsModalVisibility(false);
      void fetchUserDetails();
    } catch (e) {
      Sentry.captureException(e);
      setIsBusy(false);
    }
  };

  const updateFilters = (filters) => {
    setFilters(filters);
    setPagination({
      page: 1,
      pageSize: 10,
    });
    listMarketOffers(1, 10, filters);
  };

  const listOffers = () =>
    listMarketOffers(pagination.page, pagination.pageSize, filters);

  const onRecallOffer = (offer) => {
    // FIXME: This isn't validating the input, allowing for an empty recall
    //  reason (required field), which causes an error.
    recallOffer(
      recallOfferFormData.recall_comments,
      offer.id,
      recallOfferFormData.recall_reason.value,
    );

    setOfferRecalledStatus(offer);

    listOffers();
  };

  const onSendingBackExchange = (offer) => {
    const proposal = offer.latest_negotiation_proposal;

    const comment = t("autoComment.sendBackOffer", {
      comment: sendBackExchangeFormData.send_back_comments,
      trader: trader.name,
      quantity:
        proposal.maximum_quantity === proposal.minimum_quantity
          ? formatNumber(proposal.minimum_quantity)
          : `${formatNumber(proposal.minimum_quantity)} - ${formatNumber(
            proposal.maximum_quantity,
          )}`,
      unit: offer.unit_of_measure.code,
    });

    sendBackExchange(comment, offer);
  };

  const onMakeCounterOffer = (offer) => {
    return addNegotiationProposal(counterOfferFormData, offer);
  };

  // On component mount the list of trader is fetched
  // also market offers are fetched
  // table pagination is reset on component unmount
  // trader id is get from local storage
  useEffect(() => {
    listTermsAndConditions();
    listTraders();
    listMineAggregators();
    listCommodities();
    listUnitsOfMeasure();
    marketOffersCurrentPage(0);
    listExchangeLocations();
    traderRepresentativeDetails();
    listAdministrativeAreas();
    listExchangeLocationTypes();
    return () => {
      marketOffersCurrentPage(0);
    };
  }, []);

  useEffect(() => {
    if (!isEmpty(currentUser)) {
      if (
        !isEmpty(termsAndConditions) &&
        currentUser.terms_and_conditions_accepted_version !==
          currentUser.terms_and_conditions_latest_version
      ) {
        setTermsModalVisibility(true);
      }
      if (currentUser.representative) {
        traderRepresentativeDetails(currentUser.representative);
      }
      if (currentUser.organization) {
        updateFilters({ trader_id: currentUser.organization });
        listMarketOffers(pagination.page, pagination.pageSize, {
          ...filters,
          trader_id: currentUser.organization,
        });
      }
    }
  }, [currentUser, termsAndConditions]);

  useEffect(() => {
    if (
      trader.name &&
      !trader.physical_address &&
      !(history.location.state && history.location.state.fromProfileUpdate)
    ) {
      history.push("/representative/profile", { updateDetails: true });
    }
  }, [trader]);

  useEffect(async () => {
    if (traderRepresentative) {
      const { language } = traderRepresentative;
      if (
        language &&
        selectedLanguage &&
        language !== selectedLanguage &&
        // Don't change the language if we're coming from the profile update
        // form, as the language changes will interfere with each other.
        !(history.location.state && history.location.state.fromProfileUpdate)
      ) {
        await onChangeLanguage({ value: language });
      }
    }
  }, [traderRepresentative]);

  return (
    <PageStandard
      title={t("marketOfferView.home")}
      additional={
        <ButtonsWrapper>
          <Button onClick={onClickPrintHome}>{t("common.print")}</Button>
        </ButtonsWrapper>
      }
    >
      {/* <OfferCard offer={marketOffers[0]} /> */}
      <Grid>
        <Row>
          <Col sm={12}>{help}</Col>
        </Row>
        <Row>
          <Col sm={12}>
            <MarketOffers
              filterPanel={
                <Filters
                  key="filter-panel"
                  aggregators={aggregators}
                  commodities={commodities}
                  unitsOfMeasure={unitsOfMeasure}
                  filters={filters}
                  setFilters={setFilters}
                  updateFilters={(updatedFilters) =>
                    updateFilters({
                      ...updatedFilters,
                      trader_id: currentUser.organization,
                    })
                  }
                />
              }
              administrativeAreas={administrativeAreas}
              filters={filters}
              commodities={commodities}
              // recallsList={recallsList}
              pagination={pagination}
              aggregators={aggregators}
              errorMessageTrader={errorMessageTrader}
              trader={trader}
              isFetching={isFetching}
              isFetchingMarketOffers={isFetchingMarketOffers}
              errorMessageMarketOffers={errorMessageMarketOffers}
              marketOffers={marketOffers}
              marketOffersPage={marketOffersPage}
              onPageChangeMarketOffers={onPageChangeMarketOffers}
              marketOffersCount={marketOffersCount}
              onClickViewMarketOffer={onClickViewMarketOffer}
              listMarketOffers={listOffers}
              refreshContent={refreshContent}
              listAggregatorAdministrators={listAggregatorAdministrators}
              onValidateRecall={onRecallOffer}
              onValidateSendingBack={onSendingBackExchange}
              addCounterOffer={onMakeCounterOffer}
              counterOfferFieldErrors={counterOfferFieldErrors}
              customExchangeLocations={customExchangeLocations}
              exchangeLocationTypes={exchangeLocationTypes}
            />
          </Col>
        </Row>
      </Grid>

      <TermsAndConditionsModal
        termsAndConditions={termsAndConditions}
        isBusy={isBusy}
        show={isTermsModalVisible}
        onTermsSubmit={onTermsSubmit}
      />
    </PageStandard>
  );
};

// propTypes for the RepresentativeHome component
RepresentativeHome.propTypes = {
  administrativeAreas: PropTypes.array,
  fetchUserDetails: PropTypes.func.array,
  listTraders: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  errorMessageTrader: PropTypes.string.isRequired,
  trader: PropTypes.object.isRequired,
  traderRepresentative: PropTypes.object.isRequired,
  listMarketOffers: PropTypes.func.isRequired,
  marketOffersCurrentPage: PropTypes.func.isRequired,
  isFetchingMarketOffers: PropTypes.bool.isRequired,
  errorMessageMarketOffers: PropTypes.string.isRequired,
  marketOffers: PropTypes.array.isRequired,
  marketOffersPage: PropTypes.number.isRequired,
  marketOffersCount: PropTypes.number.isRequired,
  history: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  recallOfferFormData: PropTypes.object.isRequired,
  sendBackExchangeFormData: PropTypes.object.isRequired,
  recallOffer: PropTypes.func.isRequired,
  sendBackExchange: PropTypes.func.isRequired,
  listAdministrativeAreas: PropTypes.func.isRequired,
  listCommodities: PropTypes.func.isRequired,
  listMineAggregators: PropTypes.func.isRequired,
  listUnitsOfMeasure: PropTypes.func.isRequired,
  aggregators: PropTypes.array.isRequired,
  commodities: PropTypes.array.isRequired,
  unitsOfMeasure: PropTypes.array.isRequired,
  listAggregatorAdministrators: PropTypes.func.isRequired,
  refreshContent: PropTypes.func.isRequired,
  setOfferRecalledStatus: PropTypes.func.isRequired,
  counterOfferFormData: PropTypes.object.isRequired,
  counterOfferFieldErrors: PropTypes.object.isRequired,
  addNegotiationProposal: PropTypes.func.isRequired,
  listExchangeLocations: PropTypes.func.isRequired,
  listExchangeLocationTypes: PropTypes.func.isRequired,
  traderRepresentativeDetails: PropTypes.func.isRequired,
  customExchangeLocations: PropTypes.array.isRequired,
  exchangeLocationTypes: PropTypes.array.isRequired,
  termsAndConditions: PropTypes.array.isRequired,
  listTermsAndConditions: PropTypes.func.isRequired,
};

// Starting from the redux state it gets data related to logged in user
export const mapStateToProps = (state) => {
  return {
    // Properties related to traders list
    isFetching:
      state.listMarketOffers.isFetching ||
      state.listTraders.isFetching ||
      state.listCommodities.isFetching ||
      state.listMineAggregators.isFetching ||
      state.listUnitsOfMeasure.isFetching ||
      state.listOfferNegotiationProposals.isFetching ||
      state.listExchangeLocations.isFetching ||
      state.listExchangeLocationTypes.isFetching ||
      state.listAdministrativeAreas.isFetching,
    errorMessageTrader: state.listTraders.errorMessage,
    trader: adaptTrader(state.listTraders.data.results),
    traderRepresentative:
      state.traderRepresentativeDetails &&
      state.traderRepresentativeDetails.data,
    // Properties related to market offers
    isFetchingMarketOffers: state.listMarketOffers.isFetching,
    errorMessageMarketOffers: state.listMarketOffers.errorMessage,
    marketOffers: adaptMarketOffers(state.listMarketOffers.data.results),
    marketOffersPage: state.marketOffersCurrentPage.number,
    marketOffersCount: valueOrDefault(state.listMarketOffers.data.count, 0),
    recallOfferFormData:
      (state.form && state.form.recallOffer && state.form.recallOffer.values) ||
      {},
    sendBackExchangeFormData:
      (state.form &&
        state.form.sendingBackExchange &&
        state.form.sendingBackExchange.values) ||
      {},
    counterOfferFormData:
      (state.form &&
        state.form.counterOffer &&
        state.form.counterOffer.values) ||
      {},
    currentUser: state.userDetail.data,
    commodities: orderByLocalName(state.listCommodities.data.results),
    aggregators: state.listMineAggregators.data.results,
    unitsOfMeasure: state.listUnitsOfMeasure.data.results,
    counterOfferFieldErrors:
      (state.form &&
        state.form.counterOffer &&
        state.form.counterOffer.syncErrors) ||
      {},
    negotiationProposals: state.listOfferNegotiationProposals.data.results,
    customExchangeLocations: state.listExchangeLocations.data.results,
    exchangeLocationTypes: state.listExchangeLocationTypes.data.results,
    administrativeAreas: state.listAdministrativeAreas.data.results,
    termsAndConditions:
      state.termsAndConditions.data.results &&
      state.termsAndConditions.data.results.length
        ? state.termsAndConditions.data.results.find(
          (terms) => terms.target === "aggregator_admins_farmers_traders",
        )
        : undefined,
    // recallsList: state.listRecalls && state.listRecalls.data.results,
  };
};

// Maps functions to dispatch actions
export const mapDispatchToProps = (dispatch) => {
  return {
    fetchUserDetails: () => dispatch(userDetail("", "", true)),
    listTermsAndConditions: () =>
      dispatch(listTermsAndConditions({ page_size: 9999, page: 1 })),
    listAdministrativeAreas: () =>
      dispatch(listAdministrativeAreas({ page_size: 9999, page: 1 })),
    listTraders: () => dispatch(listTraders()),
    traderRepresentativeDetails: (id) =>
      dispatch(traderRepresentativeDetails(id)),
    listMarketOffers: (page, page_size, filters) =>
      dispatch(
        listMarketOffers({
          page,
          page_size: page_size,
          quality: filters.quality,
          status: filters.status,
          buy_commodity_id: filters.commodity_id,
          tagged_aggregator_id: filters.aggregator_id,
          unit_of_measure: filters.unit_of_measure_id,
          ordering: "-last_update_datetime",
          trader_id: filters.trader_id,
        }),
      ),
    listCommodities: () =>
      dispatch(listCommodities({ page: 1, page_size: 200 })),
    listMineAggregators: () => dispatch(listMineAggregators()),
    listUnitsOfMeasure: () => dispatch(listUnitsOfMeasure()),
    listAggregatorAdministrators: (id) =>
      dispatch(
        listAggregatorAdministrators({
          aggregator: id,
        }),
      ),
    listOfferNegotiationProposals: (id) =>
      dispatch(
        listOfferNegotiationProposals({
          trader_market_offer_id: id,
        }),
      ),
    marketOffersCurrentPage: (number) =>
      dispatch(marketOffersCurrentPage(number)),
    recallOffer: (comment, id, reason) => {
      dispatch(
        addMarketOfferRecall({
          trader_market_offer: id,
          recall_comments: comment,
          recall_reason: reason,
        }),
      );
      dispatch(
        addOfferComment({
          trader_market_offer: id,
          text: comment,
          uuid: uuidv4(),
          trader_market_offer_status: OfferStatus.Recalled,
        }),
      );
    },
    sendBackExchange: (comment, offer) => {
      dispatch(
        updateMarketOffer(offer.id, {
          ...offer,
          price: (offer.price && offer.price.split(" ")[1]) || 0,
          status: OfferStatus.ExchangeDetailsDisputed,
        }),
      );

      dispatch(
        addOfferComment({
          trader_market_offer: offer.id,
          text: `${comment}`,
          uuid: uuidv4(),
          trader_market_offer_status: OfferStatus.ExchangeDetailsDisputed,
        }),
      );
    },
    addNegotiationProposal: (proposal, offer) => {
      const has_custom_exchange_location =
        typeof proposal.delivery_location.value === "object";

      const adaptedProposal = {
        minimum_quantity: valueOrDefault(proposal.minimum_quantity, ""),
        maximum_quantity: valueOrDefault(
          offer.offer_type === OFFER_TYPES.DIRECT_OFFER
            ? proposal.minimum_quantity
            : proposal.maximum_quantity,
          "",
        ),
        price: valueOrDefault(proposal.price, ""),
        text: valueOrDefault(proposal.text, ""),
        planned_exchange_time:
          proposal.planned_exchange_time &&
          proposal.planned_exchange_time.toISOString
            ? proposal.planned_exchange_time.toISOString()
            : undefined,
        delivery_location: has_custom_exchange_location
          ? proposal.delivery_location.value.deliveryLocationType
          : valueOrDefault(
            proposal.delivery_location && proposal.delivery_location.value,
            undefined,
          ),
        custom_delivery_location: has_custom_exchange_location
          ? proposal.delivery_location.value.custom_delivery_location
          : undefined,
        custom_delivery_location_type: has_custom_exchange_location
          ? proposal.delivery_location.value.custom_delivery_location_type
          : undefined,
        trader_market_offer: offer.id,
        uuid: uuidv4(),
      };

      dispatch(createOfferNegotiationProposal(adaptedProposal));

      return adaptedProposal;
    },
    listExchangeLocations: () => dispatch(listExchangeLocations()),
    listExchangeLocationTypes: () => dispatch(listExchangeLocationTypes()),
    refreshContent: (offer) => {
      dispatch(listOfferCommentsReset());
      dispatch(listOfferNegotiationProposalsReset());
      dispatch(listRejections({ trader_market_offer_id: offer.id }));
      dispatch(listRecalls({ trader_market_offer_id: offer.id }));
      dispatch(listOfferComments({ trader_market_offer_id: offer.id }));
      dispatch(
        listOfferNegotiationProposals({ trader_market_offer_id: offer.id }),
      );
    },
    setOfferRecalledStatus: (offer) => {
      dispatch(
        updateMarketOffer(offer.id, {
          ...offer,
          price: 0,
          status:
            offer.status === OfferStatus.ExchangeDetailsUpdated
              ? OfferStatus.ExchangeDetailsDisputed
              : OfferStatus.Recalled,
          planned_exchange_time: undefined,
        }),
      );
    },
  };
};

// The component uses the redux store
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(RepresentativeHome));

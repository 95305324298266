import React, { useEffect, useState } from "react";
import { withRouter, Redirect } from "react-router-dom";

import FullscreenLoading from "../../components/FullscreenLoading";

// A component used to redirect user to the right home page according to its
// profile
export const LoginRedirect = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [userProfile, setUserProfile] = useState(undefined);
  const [selectedCountry, setSelectedCountry] = useState(undefined);

  // On component mount the user profile and the current user profile is
  // checked
  useEffect(() => {
    const userProfile = localStorage.getItem("user_profile");
    const selectedCountry = localStorage.getItem("selected_country");
    setUserProfile(userProfile);
    setSelectedCountry(selectedCountry);
    setIsLoading(false);
  }, []);

  // If loading a loading indicator is displayed
  if (isLoading) {
    return <FullscreenLoading isVisible />;
  }

  // If user is representative the representative home page is displayed
  if (userProfile === "representative") {
    return <Redirect to="/representative/" />;
  }

  if (userProfile === "guest") {
    return (
      <Redirect to="/guest/" />
    );
  }

  // If user is wfp country manager the country selection page is displayed
  if (userProfile === "wfp_manager") {
    return (
      selectedCountry ?
        <Redirect to="/wfpManager/" /> :
        <Redirect to="/wfpManager/countrySelection" />
    );
  }

  if (userProfile === "administrator") {
    return (
      <Redirect to="/loginForbiddenAdministrator/" />
    );
  }

  if(!userProfile){
    return <Redirect to="/login"/>;
  }
  // If user profile is not managed a message is displayed to user
  return <Redirect to="/loginForbidden/" />;
};

// The component uses the router to redirect user
export default withRouter(LoginRedirect);
